export enum AppRoutes{
    BASE = '/',
    HOME = '/',
    FAIL = '/falha',
    EXTERNAL = 'https://www.santanderportalrh.com.br/santanderexterno',
    MEU_INSS = 'https://meu.inss.gov.br/#/login',
    CERTIFICATE  =  '/Atestado',
    HEALTH_CERTIFICATE = '/Saude',
    NEW_HEALTH_CERTIFICATE  = '/Saude/NovoAtestado',
    FAMILY_CERTIFICATE = '/Familia',
    NEW_FAMILY_CERTIFICATE = '/Familia/NovoAtestado',
    ABSENCE_LIST = '/Afastamento',
    ACCEPT_TERM = '/Afastamento/TermoAceite/{id}',
    ABSENCE_DOCUMENTS_PAGE = '/Afastamento/Documentos/{id}',
    ABSENCE_DETAILS_PAGE = '/Afastamento/Detalhes/{id}',
    AUTHENTICATION_ERROR = '/ErroAutenticacao',
    PROFILE_ERROR = '/PerfilInvalido',
    PROFILE_ERROR_ESTAG = '/PerfilInvalidoEstagiario',
    TESTE_UPLOAD = '/teste'
}
