import { AbsenceProcessDocument } from './../interfaces/absence-process-document.interface';
import { EncriptionService } from '@afe/base-geral';
import { Injectable } from '@angular/core';
import {
  AuthenticateRequestUpload,
  AuthenticateResponse,
  DownloadFinalResponse,
  DownloadService,
  DownloadServiceRequest,
  UploadService,
  UploadServiceRequest,
  UploadServiceResponse,
} from '@taa/file-transfer';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HubConnectorService } from './hubConnector.service';
import { SettingsService } from './settings.service';
import { WkdEncryptionService } from './wkd-encryption.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(public uploadService: UploadService,
    public downloadService: DownloadService,
    private readonly hubService: HubConnectorService,
    private readonly encryptService: EncriptionService,
    private readonly wkdEncrypt: WkdEncryptionService,
    public settingService: SettingsService) { }

  upload(file: File): Observable<UploadServiceResponse> {
    const uploadRequest: UploadServiceRequest = {
      base64FileSize: file.size,
      documentType: {
        company: this.settingService.COMPANY_CODE_TAA,
        documentName: this.settingService.MEDICAL_CERTIFICATE,
      },
      repositoryAlias: this.settingService.ALIAS_TAA,
      clientNode: this.settingService.CLIENT_NODE_TAA,
      file,
      fileHash: undefined,
      mimeType: file.type,
    };

    return this.encryptService.encrypt(this.getBase64(file).toString())
      .pipe(
        switchMap((fileEncrypted) => {
          uploadRequest.fileHash = fileEncrypted;
          return this.authenticateTAAUpload(file)
        }),
        switchMap((authParams: AuthenticateResponse | AuthenticateRequestUpload) => {
          const authenticationParams = Object.assign(new AuthenticateResponse, authParams);
          return this.uploadService
                     .uploadExternalFile(authenticationParams, uploadRequest)
        })
      )
  }

  private readonly getDateNumber = 183;

  private authenticateTAAUpload(file: File): Observable<AuthenticateResponse | AuthenticateRequestUpload> {
    const today = new Date();
    const todayString = today.toLocaleDateString('pt-BR').replace(/\//g, "-");
    const expireDateSixMonths = new Date(new Date().setDate(today.getDate() + this.getDateNumber)).toLocaleDateString('pt-BR').replace(/\//g, "-");
    const emptyValue = ' '
    const request = {
      "codeAppOrigin": this.settingService.CLIENT_NODE_TAA,
      "repository": this.settingService.ALIAS_TAA,
      "mimeType": file.type,
      "base64FileSize": file.size.toString(),
      "metadados": [{
        "tipoMetadato": "GN_DATE",
        "tipoFisico": "DATE",
        "multiValuado": "N",
        "listaValores": { "valor": todayString }
      },
      {
        "tipoMetadato": "GN_CREATIONDATE",
        "tipoFisico": "DATE",
        "multiValuado": "N",
        "listaValores": { "valor": todayString }
      },
      {
        "tipoMetadato": "GN_CADUCITYDATE",
        "tipoFisico": "DATE",
        "multiValuado": "N",
        "listaValores": { "valor": expireDateSixMonths}
      },
      {
        "tipoMetadato": "GN_NAME",
        "tipoFisico": "STRING",
        "multiValuado": "N",
        "listaValores": { "valor": file.name }
      },
      {
        "tipoMetadato": "GN_VALIDITYDATE",
        "tipoFisico": "DATE",
        "multiValuado": "N",
        "listaValores": { "valor": expireDateSixMonths }
      },
      {
        "tipoMetadato": "ID_CONFIAVEL",
        "tipoFisico": "STRING",
        "multiValuado": "N",
        "listaValores": { "valor": "S" }
      },
      {
        "tipoMetadato": "CONTRATO",
        "tipoFisico": "STRING",
        "multiValuado": "N",
        "listaValores": { "valor": emptyValue }
      },
      {
        "tipoMetadato": "USER_NUM",
        "tipoFisico": "STRING",
        "multiValuado": "N",
        "listaValores": { "valor": emptyValue }
      }],
      "serviceType": "U",
      "timeIni": Date.now().toString()
    };

    return from(this.wkdEncrypt.encryptObjectByReference(request))
      .pipe(
        switchMap((encrypted) => {
          return this.hubService.post<AuthenticateResponse | AuthenticateRequestUpload>(this.settingService.TaaAuthenticate, encrypted);
        }),
      )
  }

  private getBase64(file) {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    return readerOnLoad();

    function readerOnLoad() {
      return reader.onload = function () {
        return reader.result;
      };
    }
  }

  public download(gnId: string): Observable<DownloadFinalResponse | HttpErrorResponse> {
    const uploadRequest: DownloadServiceRequest = {

      repositoryAlias: this.settingService.ALIAS_TAA,
      codeAppOrigin: this.settingService.CLIENT_NODE_TAA,
      isExternal: true
    };

    return this.authenticateTAADownload(gnId)
      .pipe(

        switchMap((authParams: AuthenticateResponse | AuthenticateRequestUpload) => {
          const authenticationParams = Object.assign(new AuthenticateResponse, authParams);
          return this.downloadService
                     .downloadFile(authenticationParams, uploadRequest)
        })
      )
  }
  private authenticateTAADownload(gnId: string) {
    const request = {
      "gnId": gnId,
      "codeAppOrigin":  this.settingService.CLIENT_NODE_TAA,
      "serviceType": "D",
      "repository":  this.settingService.ALIAS_TAA,
      "timeIni": Date.now().toString()
    }
    return from(this.wkdEncrypt.encryptObjectByReference(request))
    .pipe(
      switchMap((encrypted) => {
        return this.hubService.post<AuthenticateResponse | AuthenticateRequestUpload>(this.settingService.TaaAuthenticate, encrypted);
      }),
    )
  }
}
