import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";

@Injectable()
export class BreakpointService {

    constructor(public breakpointObserver: BreakpointObserver) {}


    isMobile(): boolean {
        return this.breakpointObserver.isMatched('(max-width: 599px)');
    }
}