import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { LocalStorageService } from './storage/local-storage.service';

@NgModule({})
export class LocalStorageModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: LocalStorageModule,
            providers: [ LocalStorageService ]
        };
    }
}
