import { Injectable } from '@angular/core';
import { Employee } from '../interfaces/employee.interface';
import { SettingsService } from './settings.service';
import { ManipulationDatetimeService } from './utils/manipulation-datetime.service';


@Injectable()
export class EmployeeService {
  private readonly userEmployee: Employee;
  private readonly currentDay: Date = new Date(new Date().setHours(0, 0, 0, 0));

  constructor(
    public serviceDateTime: ManipulationDatetimeService,
    public settingsService: SettingsService,
  ) { }

  // getValidRequetDays(daysRequest) {
  //   return daysRequest.filter(date => this.isDayRequestValid(date.date));
  // }

  // getValidRequetDaysPending(daysRequest: DayRequest[]): DayRequest[] {
  //   return daysRequest.filter(date => this.isDayRequestValid(date.date));
  // }

  // getUniqueUserDates(user: Employee): Date[] {
  //   const listDates: Date[] = [];
  //   if (user.requestsApprove) {
  //     user.requestsApprove.map(r => {
  //       if (r.requesteds) {
  //         r.requesteds.map(d => {
  //           if (!(listDates.indexOf(d.date) > -1)) {
  //             listDates.push(d.date);
  //           }
  //         });
  //       }
  //     });
  //   }
  //   return listDates;
  // }

  // setUserEmployee(employee: Employee) {
  //   this.userEmployee = employee;
  // }

  // isManager(employee: Employee): boolean {
  //    const value = employee.role.id;
  //   return [
  //     this.settingsService.ROLE_MANAGER.id,
  //     this.settingsService.ROLE_MNG_EPY.id
  //   ].indexOf(value) > -1;
  // }

  // isEmployee(employee: Employee){
  //   return !this.isManager(employee) && !this.isManagerMaster(employee);
  // }

  // isManagerMaster(employee: Employee): boolean {
  //   return this.settingsService.ROLE_VP.id === employee.role.id;
  // }

  // isDelegated(employee: Employee): boolean {
  //   return  this.settingsService.ROLE_DELEGATED_EMPLOYEE.id === employee.role.id ||
  //           this.settingsService.ROLE_DELEGATED_MANAGER.id === employee.role.id ||
  //           this.settingsService.ROLE_DELEGATED_MNG_EPY.id === employee.role.id ||
  //           this.settingsService.ROLE_DELEGATED_VP.id === employee.role.id;
  // }

  // isVP(employee: Employee): boolean {
  //   return this.settingsService.ROLE_DELEGATED_VP.id === employee.role.id ||
  //          this.settingsService.ROLE_DELEGATED_EMPLOYEE.id === employee.role.id ||
  //          this.settingsService.ROLE_DELEGATED_MANAGER.id === employee.role.id ||
  //          this.settingsService.ROLE_DELEGATED_MNG_EPY.id === employee.role.id ||
  //          this.settingsService.ROLE_VP.id === employee.role.id;
  // }



  // isCurrentManager(): boolean {
  //   return this.isManager(this.userEmployee);
  // }


  // isCurrentEmployee():boolean{
  //   return this.isEmployee(this.userEmployee)  ;
  // }

  // isCurrentManagerMaster(): boolean {
  //   return this.isManagerMaster(this.userEmployee);
  // }

  // isCurrentVP(): boolean {
  //   return this.isVP(this.userEmployee);
  // }

  // getSaldo(user: Employee): number {
  //   return user.balanceHours.leftOver ? this.serviceDateTime.hourToMinute(user.balanceHours.leftOver) : 0;
  // }

  // getUserEmployee(){
  //   return this.userEmployee;
  // }

  // hasTimeBankNegative(user: Employee): boolean {
  //   return this.getSaldo(user) < 0;
  // }

  // hasSignedTerm(user: Employee): boolean {
  //   return user.termExtensionStatus.id === this.settingsService.TERM_EXTENSION_ASSIGN.id;
  // }

  // hasTimeBankExceeded(user: Employee): boolean {
  //   return this.compareBalanceToExceeded(user) < 0;
  // }

  // hasTimeBankExceededWithTerm(user: Employee): boolean {
  //   return this.hasTimeBankExceeded(user) && this.hasSignedTerm(user);
  // }

  // isBalanceEqualsLimit(user: Employee): boolean {
  //   return this.compareBalanceToExceeded(user) === 0;
  // }



  // isNomineeActive(user: Nominee): boolean {
  //   return user.person && user.person.status && user.person.status.id && user.person.status.id === this.settingsService.STATUS_ACTIVE.id;
  // }
  // isNomineeCurrentDelegated(user: Nominee): boolean {
  //   return this.hasNomineeDateLimit(user) || this.isNomineeIndeterminateDate(user);
  // }

  // hasNomineeDateLimit(user: Nominee): boolean {
  //   return this.hasNomineeType(user) && this.hasPeriodNominee(user);
  // }

  // getEmployeeWithLeftHour(employee: Employee): Employee {
  //   if (employee) {
  //     const list = [];
  //     employee.balanceHours.balanceHour.map(b => list.push(b.minutes));
  //   }
  //   return employee;
  // }

  // decryptEmployee(employee: Employee): Employee {
  //   const emp = employee;
  //   forkJoin([
  //     this.serviceEncription.decrypt(emp.person.id),
  //     this.serviceEncription.decrypt(emp.manager.person.id)
  //   ])
  //   .subscribe(res => {
  //     employee.person.id = res[0];
  //     employee.manager.person.id = res[1];
  //   });
  //   return employee;
  // }

  // hasSwitchMenu(roleId: string): boolean {
  //   return roleId === this.settingsService.ROLE_DELEGATED_EMPLOYEE.id ||
  //          roleId === this.settingsService.ROLE_DELEGATED_MANAGER.id ||
  //          roleId === this.settingsService.ROLE_MNG_EPY.id ||
  //          roleId === this.settingsService.ROLE_DELEGATED_MNG_EPY.id;
  // }

  // private isNomineeIndeterminateDate(user: Nominee): boolean {
  //   return this.hasNomineeType(user) && !this.hasPeriodNominee(user);
  // }

  // private hasNomineeType(user): boolean {
  //   return user.type && user.type.id && user.type.id !== '0';
  // }

  // private hasPeriodNominee(user: Nominee): boolean {
  //   return user.type.period
  //     && user.type.period.start
  //     && user.type.period.end
  //     && this.isDayRequestValid(user.type.period.end);
  // }

  // private isDayRequestValid(day): boolean {
  //   return new Date(day).getTime() >= new Date(this.currentDay).getTime();
  // }

  // private compareBalanceToExceeded(user: Employee): number {
  //   const MINUTO = 60;
  //   const limit = user.overtimeCelling * MINUTO;
  //   const balance = this.serviceDateTime.hourToMinute(user.balanceHours.leftOver);
  //   return limit - balance;
  // }
}
