import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService, HubConnectorService } from '.';

import { Auth, User } from '../model';

@Injectable()
export class UserService {

    private readonly user: User;

    public authenticated: EventEmitter<any> = new EventEmitter();
    constructor(
        private readonly settingsService: SettingsService,
        private readonly hubconnector: HubConnectorService
    ) {}

    // getUser(auth: Auth, _router: Router): Promise<User> {
    //     const url = this.settingsService.getUrl('byAuth', false);
    //     return this.hubconnector.post(url, auth ).toPromise()
    //         .then(response => this.user = response)
    //         .catch(this.handleError);
    // }

    // configUser(auth: Auth, _router: Router): Promise<User> {
    //     return this.configUserObserver(auth, _router).
    //         toPromise().then(config => this.user);
    // }

    // configUserObserver(auth: Auth, _router: Router): Observable<User> {
    //     return new Observable<User>(
    //         config => {
    //             this.getUser(auth, _router).then((result: User) => {
    //                 this.user = result;
    //                 config.complete();
    //             },
    //                 errorFetch => {
    //                     config.complete();
    //                     // this.logService.print('error from fetch user by init:', errorFetch);
    //                     _router.navigate(['/alert-critic', 'vacation/portal']);
    //                 }
    //             );
    //         }
    //     );
    // }


    // getUserLogged(): User {
    //     return this.user;
    // }

    // handleError(error: any): Promise<any> {
    //     return Promise.reject(error.json());
    // }
}
