import { Component, Input, OnInit } from '@angular/core';
import { CidResponse } from './../../interfaces/cid-response.interface';

@Component({
  selector: 'wkd-cids-resume',
  templateUrl: './cids-resume.component.html',
  styleUrls: ['./cids-resume.component.scss']
})
export class CidsResumeComponent implements OnInit {
  @Input() public label = '';
  @Input() public cidList:  CidResponse[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
