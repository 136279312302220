import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(value: number): string {
    const MINUTE = 60;
    const DECIMAL = 10;

    let negative = false;
    if (value <= 0) {
      negative = true;
      value = value*-1;
    }
    const h = Math.floor(value / MINUTE);
    const m = Math.floor(value % MINUTE);
    return `${(negative?'-':'')}${(h < DECIMAL ? '0' + h : h)}:${(m < DECIMAL ? '0' + m : m)}`;
  }
}
