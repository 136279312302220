import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { InfoComponent } from './components';
import { FileTesteComponent } from './components/file-teste/file-teste.component';
import { AuthenticationErrorComponent } from './pages/authentication-error/authentication-error.component';
import { ExpiredSessionComponent } from './pages/expired-session/expired-session.component';
import { HomeComponent } from './pages/home/home.component';
import { NotAllowedComponent } from './pages/not-allowed/not-allowed.component';
import { AuthenticateService } from './services/authenticate.service';
import { NotAllowedEstagComponent } from './pages/not-allowed-estag/not-allowed-estag.component';

const routes: Routes = [

    {
        path: '',
        component: AppComponent,

        children: [
            {
                path: '',
                component: HomeComponent
            }, 
            {
                path: 'Saude',   
                canActivate: [AuthenticateService],  
                runGuardsAndResolvers: 'always',     
                loadChildren: () => import('./modules/medical/medical.module').then(m => m.MedicalModule)
            },
            {
                path: 'Familia',
                canActivate: [AuthenticateService],  
                runGuardsAndResolvers: 'always',                
                loadChildren: () => import('./modules/family/family.module').then(m => m.FamilyModule)
            },
            {
                path: 'Afastamento',
                canActivate: [AuthenticateService],  
                runGuardsAndResolvers: 'always',
                loadChildren: () => import('./modules/medical-absence/medical-absence.module').then(m => m.MedicalAbsenceModule)
            },
            {
                path: 'SessaoExpirada',
                component: ExpiredSessionComponent
            },
            {
                path: 'ErroAutenticacao',
                component: AuthenticationErrorComponent
            },
            {
                path: 'PerfilInvalido',
                component: NotAllowedComponent
            },
            {
                path: 'PerfilInvalidoEstagiario',
                component: NotAllowedEstagComponent
            }
        ]
    },
    {
        path: 'readiness',
        component: InfoComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'home'
            },
            {
                path: 'home',
                runGuardsAndResolvers: 'always',
                component: InfoComponent
            }
        ]
    },
    {
        path: 'liveness',
        component: InfoComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'home'
            },
            {
                path: 'home',
                runGuardsAndResolvers: 'always',
                component: InfoComponent
            }
        ]
    },

    {
        path: 'falha',
        component: AuthenticationErrorComponent
    },
    {
        path: '**',
        redirectTo: 'falha'
    }
 
    /*
    { path: '', canActivate: [AuthenticateService], component: BlankComponent},
    { path: 'home', canActivate: [AuthenticateService], component: MainComponent},
    { path: '**', redirectTo: '' }*/
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: false, scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
