
import { AbscenceStatus } from './../../enum/absence-status.enum';
import { Component, Input } from '@angular/core';
import { Status } from '../../interfaces/status.interface';

@Component({
  selector: 'wkd-list-status',
  templateUrl: './list-status.component.html',
  styleUrls: ['./list-status.component.scss']
})
export class ListStatusComponent{
  @Input() status: Status;
  absenceStatus = AbscenceStatus

}
