import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable, forkJoin, of } from 'rxjs';
import { HubConnectorComponent, RequestOptions, EncriptionService }from '@afe/base-geral';
import { map, mergeMap, retry } from 'rxjs/operators';

import {
    GetTimeLimitsResponse, AddCallReleaseResponse,
    ApproveCallReleaseResponse, GetCallReleaseResponse,
    GetManagersResponse, getEmployeeDetails, GetEmployeeByManagerResponse,
    GetNomineeResponse, DeleteNomineeResponse, GetNominee, ConfirmNominee,
    TimeLimit, RequestReason, Employee, CallRelease, Nominee, Result,
    ApproveCallReleaseParams, GetDepartmentResponse, GetReasonsResponse, AddCallRelease,
    GetCallRelease,GetManagers, GetEmployee, GetEmployeeByManager, GetDepartment, NomineePeriod
    , ConfirmNomineeResponse, Department, Term, PasswordRequest, ApprovePasswordRequest, PasswordRequestResponse, ApprovePasswordRequestResponse, Person
} from '../model/models.model';
import { SettingsService, EmployeeService } from '.';
import { Factory } from '../lib/factory';


@Injectable()
export class AppService {
    constructor(
        public settingsService: SettingsService,
        public hubconnector: HubConnectorComponent,
        public httpClient: HttpClient,
        public serviceEmployee: EmployeeService,
        public cryptService: EncriptionService
    ) { }

    // APPSERVICE
    // GetTimeLimits(type?: string): Observable<TimeLimit[]> {
    //     const options = this.getOptions();
    //     options.params['type'] = type;
    //     options.params['token'] = 'token';



    // GetRequestReason(type?: string): Observable<RequestReason[]> {
    //     const options = this.getOptions();
    //     options.params['type'] = type;
    //     options.params['token'] = 'token';



    // AddCallRelease(requesterId?: string, employeeId?: string,
    //     approverId?: string, type?: string, reasonId?: string,
    //     justification?: string, requestedTime?: string,
    //     occurrences?: Array<Date>, departmentId?: string, exceptionWeekend?: string): Observable<AddCallReleaseResponse> {
    //     return forkJoin([
    //         this.cryptService.encrypt(requesterId),
    //         this.cryptService.encrypt(employeeId),
    //         this.cryptService.encrypt(approverId)
    //     ]).pipe(mergeMap(res => {
    //         const data = new AddCallRelease();
    //         const indexApprover = 2;
    //         data.requesterId = res[0]; // FTO EHT
    //         data.employeeId = res[1];
    //         data.approverId = res[indexApprover];
    //         data.type = type;
    //         data.reasonId = reasonId;
    //         data.justification = justification;
    //         data.requestedTime = requestedTime;
    //         data.occurrences = occurrences;
    //         data.departmentId = departmentId || null;
    //         data.exceptionWeekend = exceptionWeekend;

    //         return this.hubconnector.post(this.settingsService.CallRelease, data, this.getOptions())
    //             .pipe(map((reponse: HttpResponse<AddCallReleaseResponse>) => reponse.body))
    //             .pipe(map(resp => Factory.Create(AddCallReleaseResponse, resp)));
    //     }));
    // }

    // ApproveCallRelease(obj: any): Observable<ApproveCallReleaseResponse> {
    //     return forkJoin([
    //         this.cryptService.encrypt(obj.approverId),
    //         this.cryptService.encrypt(obj.employeeId)
    //     ]).pipe(mergeMap(res => {
    //         const data = new ApproveCallReleaseParams();
    //         data.approverId = res[0];
    //         data.employeeId = res[1];
    //         data.action = obj.action;
    //         data.timeExtentionId = obj.timeExtentionId;
    //         data.occurrences = obj.occurrences;
    //         data.message = obj.message;

    //         return this.hubconnector.put(this.settingsService.CallRelease, data, this.getOptions())
    //             .pipe(map((reponse: HttpResponse<ApproveCallReleaseResponse>) => reponse.body))
    //             .pipe(map(resp => Factory.Create(ApproveCallReleaseResponse, resp)));
    //     }));
    // }

    // GetCallReleaseByOwner(emplId?: string, status?: string): Observable<Array<CallRelease>> {
    //     return this.GetCallRelease(emplId, 'F' + status, this.settingsService.GETCALLRELEASE_BY_OWNER);
    // }

    // GetCallReleaseByApprover(emplId?: string, status?: string): Observable<Array<CallRelease>> {
    //     return this.GetCallRelease(emplId, status, this.settingsService.GETCALLRELEASE_BY_APPROVER);
    // }

    // public GetCallReleaseByRequester(emplId?: string, status?: string): Observable<Array<CallRelease>> {
    //     return this.GetCallRelease(emplId, status, this.settingsService.GETCALLRELEASE_BY_REQUESTER);
    // }

    /*
    * Metodo utilizado para carregar a lista de diferentes tipos de CallRelease
    * Case: Settings.REQUEST_STATUS_PENDING when
    *   -> approvalStatus is null or undefined
    *   -> CallRelease[] -> daysToCallRelease[] -> dayRequest.status = Settings.REQUEST_STATUS_PENDING
    * Case: Settings.REQUEST_STATUS_APPROVED when
    *   -> approvalStatus.id = Settings.REQUEST_STATUS_APPROVED.id
    *   -> CallRelease[] -> daysToCallRelease[] -> dayRequest.status = Settings.REQUEST_STATUS_APPROVED
    * Case: Settings.REQUEST_STATUS_DISAPPROVED when
    *   -> approvalStatus.id = Settings.REQUEST_STATUS_DISAPPROVED.id
    *   -> CallRelease[] -> daysToCallRelease[] -> dayRequest.status = Settings.REQUEST_STATUS_DISAPPROVED
    * Case: Settings.REQUEST_STATUS_CANCELED when
    *   -> approvalStatus.id = Settings.REQUEST_STATUS_CANCELED.id
    *   -> CallRelease[] -> daysToCallRelease[] -> dayRequest.status = Settings.REQUEST_STATUS_CANCELED
    */
    // GetCallRelease(emplId?: string, status?: string, type?: string): Observable<Array<CallRelease>> {
    //     return forkJoin([
    //         this.cryptService.encrypt(emplId)
    //     ]).pipe(mergeMap(res => {
    //         const options = this.getOptions();
    //         options.params['employeeId'] = encodeURIComponent(res[0]);
    //         options.params['status'] = status;
    //         options.params['type'] = type;
    //         options.params['page'] = '1';
    //         return this.hubconnector.get(this.settingsService.CallRelease, options)
    //             .pipe(retry(5))
    //             .pipe(map((reponse: HttpResponse<GetCallReleaseResponse>) => reponse.body))
    //             .pipe(map(resp => Factory.Create(GetCallReleaseResponse, resp)))
    //             .pipe(map(resp => resp.releases || null));
    //     }));
    // }

    // Incompleto
    // GetManagers(emplId?: string, roleId?: string): Observable<Array<{manager: Person}>> {
    //     return forkJoin([
    //         this.cryptService.encrypt(emplId)
    //     ]).pipe(mergeMap(res => {
    //         const options = this.getOptions();
    //         options.params['id'] =  encodeURIComponent(res[0]);
    //         options.params['roleId'] = roleId;
    //         options.params['page'] = '1';

    //         return this.hubconnector.get(this.settingsService.GetManagers, options)
    //         .pipe(map((reponse: HttpResponse<GetManagersResponse>) => reponse.body))
    //         .pipe(map(resp => Factory.Create(GetManagersResponse, resp )))
    //         .pipe(map(resp => resp.managers || null));
    //         }));

    // }

    // public GetEmployeeDetail(emplId?: string) {
    //     return forkJoin([
    //         this.cryptService.encrypt(emplId)
    //     ]).pipe(mergeMap(() => {
    //         const options = this.getOptions();
    //         options.params["id"] = emplId;

    //         return this.hubconnector.get(this.settingsService.GetEmployeeDetail, options)
    //         .pipe(retry(5))
    //         .pipe(map((reponse: HttpResponse<getEmployeeDetailsResponse>) => reponse.body))
    //         .pipe(map(resp => Factory.Create(getEmployeeDetailsResponse, resp)))
    //         .pipe(map(item => item.employee || null));
    //     }));
    // }

    // GetEmployeeByManager(managerId?: string, employeeId?: string, departmentId?: string, profile?: string, page = 1): Observable<Array<Employee>> {
    //     return forkJoin([
    //         this.cryptService.encrypt(managerId),
    //         this.cryptService.encrypt(employeeId),
    //     ]).pipe(mergeMap((res) => {
    //         const options = this.getOptions();
    //         options.params['managerId'] = encodeURIComponent(res[0]);
    //         if(employeeId)
    //             options.params['employeeId'] = encodeURIComponent(res[1]);
    //         if(departmentId)
    //             options.params['departmentId'] = departmentId;
    //         options.params['profile'] = 'a';
    //         options.params['page'] = String(page);

    //         return this.hubconnector.get(this.settingsService.GetEmployeeByManager, options)
    //             .pipe(retry(5))
    //             .pipe(map((reponse: HttpResponse<GetEmployeeByManagerResponse>) => reponse.body))
    //             .pipe(map((resp) => Factory.Create(GetEmployeeByManagerResponse, resp)))
    //             .pipe(map((item) => item.employees || null));
    //     }));
    // }

    // GetNominee(managerId?: string, employeeId?: string,page = 0): Observable<Array<Nominee>> {

    //     return forkJoin([
    //         this.cryptService.encrypt(managerId),
    //         this.cryptService.encrypt(employeeId)
    //     ]).pipe(mergeMap((res) => {
    //         const options = this.getOptions();
    //         options.params['managerId'] =  encodeURIComponent(res[0]);
    //         options.params['employeeId'] = encodeURIComponent(res[1]);
    //         options.params['page'] = String(page);

    //     return this.hubconnector.get(this.settingsService.Nominee, options)
    //     .pipe(map((reponse: HttpResponse<GetNomineeResponse>) => reponse.body))
    //     .pipe(map(resp => Factory.Create(GetNomineeResponse, resp )))
    //     .pipe(map((item) => item.nominees || null));
    //     }));
    // }

    // ConfirmNominee(managerId?: string, nomineeId?: string, type?: string, period?: NomineePeriod) {
    //     return forkJoin([
    //         this.cryptService.encrypt(managerId),
    //         this.cryptService.encrypt(nomineeId)
    //     ]).pipe(mergeMap((res) => {
    //         const data = new ConfirmNominee();
    //         data.managerId = res[0];
    //         data.nomineeId = res[1];
    //         data.type = type;
    //         data.period = period;

    //         return this.hubconnector.post(this.settingsService.Nominee, data, this.getOptions())
    //         .pipe(map((reponse: HttpResponse<ConfirmNomineeResponse>) => reponse.body))
    //         .pipe(map((resp) => Factory.Create(ConfirmNomineeResponse, resp)));
    //     }));
    // }

    // public DeleteNominee(managerId?: string, nomineeId?: string) {
    //     return forkJoin([
    //         this.cryptService.encrypt(managerId),
    //         this.cryptService.encrypt(nomineeId)
    //     ]).pipe(mergeMap((res) => {
    //         const options = this.getOptions();
    //         options.params['managerId'] =  encodeURIComponent(res[0]);
    //         options.params['nomineeId'] = encodeURIComponent(res[1]);

    //         return this.hubconnector.delete(this.settingsService.Nominee, options)
    //         .pipe(map((reponse: HttpResponse<DeleteNomineeResponse>) => reponse.body))
    //             .pipe(map((resp) => Factory.Create(DeleteNomineeResponse, resp)));
    //     }));
    // }

    // public GetDepartments(employeeId?: string, nodeId?: string): Observable<Array<Department>> {
    //     return forkJoin([
    //         this.cryptService.encrypt(employeeId),
    //     ]).pipe(mergeMap((res) => {
    //         const options = this.getOptions();
    //         options.params['employeeId'] =  encodeURIComponent(res[0]);
    //         options.params['node'] = nodeId;

    //     return this.hubconnector.get(this.settingsService.GetDepartments, options)
    //     .pipe(map((reponse: HttpResponse<GetDepartmentResponse>) => reponse.body))
    //     .pipe(map(resp => Factory.Create(GetDepartmentResponse, resp )))
    //     .pipe(map((item) => item.departments ? (item.departments || null) : null ));
    //     }));
    // }

    // public GetTerm(): Observable<Term> {
    //     return forkJoin([
    //       // TODO: Aplicar criptografia nos dados antes de realizar a chamada.
    //       of('')
    //     ])
    //       .pipe(mergeMap(() => {
    //         return this.hubconnector.get(this.settingsService.Term, this.getOptions())
    //             .pipe(map((reponse: HttpResponse<Term>) => reponse.body))
    //             .pipe(map((resp) => Factory.Create(Term, resp)));
    //     }));
    // }

  /**
   * Cria uma nova requisição de senha para acesso offline
   * @param requesterId Id do funcionário que fez a requisicão no backend
   * @param reasonId Id da razão da requisição
   * @param justification Justificativa da requisição
   * @param incidentId Código do incidente associado a requisição
   * @param employeeId Id do funcionário no backend
   * @param departmentId Codigo do departamento solicitado
   */
//   public AddPasswordRequest(requesterId: string, reasonId: string, justification: string, incidentId: string,
//     employeeId?: string, departmentId?: string) {
//         return forkJoin([
//             this.cryptService.encrypt(requesterId),
//             this.cryptService.encrypt(employeeId),
//             this.cryptService.encrypt(reasonId),
//             this.cryptService.encrypt(justification),
//             this.cryptService.encrypt(departmentId),
//             this.cryptService.encrypt(incidentId)
//         ])
//             .pipe(mergeMap((res) => {
//             const data = new PasswordRequest();
//             data.requesterId = res[0];
//             data.employeeId = res[1];
//             data.reasonId = res[2];
//             data.justification = res[3];
//             data.departmentId = res[4];
//             data.incidentId = res[5];

//             return this.hubconnector.post(this.settingsService.HashRequest, data,  this.getOptions())
//                 .pipe(map((reponse: HttpResponse<PasswordRequestResponse>) => reponse.body))
//               .pipe(map((resp) => Factory.Create(PasswordRequestResponse, resp)));
//         }));
//     }

  /**
   * Cria uma nova requisição de senha para acesso offline
   * @param requestId Id da requisição de senha solicitada
   * @param action Ação que deve ser tomada no backend
   * @param approverId Id do funcionário no backend que aprovou a requisição
   * @param employeeId Id do funcionário no backend
   * @param departmentId Codigo do departamento solicitado
   */
//   public ApprovePasswordRequest(requestId: string, action: string, approverId: string, employeeId?: string, departmentId?: string) {
//     return forkJoin([
//         this.cryptService.encrypt(requestId.toString()),
//         this.cryptService.encrypt(action),
//         this.cryptService.encrypt(approverId),
//         this.cryptService.encrypt(employeeId),
//         this.cryptService.encrypt(departmentId),
//       ])
//       .pipe(mergeMap((res) => {
//           const data = new ApprovePasswordRequest();
//           data.requestId = res[0];
//           data.action = res[1];
//           data.approverId = res[2];
//           data.employeeId = res[3];
//           data.departmentId = res[4];

//           return this.hubconnector.put(this.settingsService.HashRequest, data, this.getOptions())
//           .pipe(map((reponse: HttpResponse<ApprovePasswordRequestResponse>) => reponse.body))
//             .pipe(map((resp) => Factory.Create(ApprovePasswordRequestResponse, resp)));
//         }));
//   }

    /**
     * Gera os headers de já com gw-app-key
     * @param searchParams coloque undefined para valor padrão de somente gw-app-key
     * @param header coloque undefned para header padrão
     */
    private getOptions(searchParams?: HttpParams, header?: HttpHeaders): RequestOptions {
        return {params: {'gw-app-key': this.settingsService.getAppKey()}};
    }

    public decrypt(msg: string) {
        return this.cryptService.decrypt(msg);
    }
}
