import { TypeResumeData } from './../../interfaces/type-resume-data.interface';
import { AbsenceType } from './../../enum/absence-type.enum';
import { ManipulationDatetimeService } from './../../services/utils/manipulation-datetime.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CreatedAbsenceResponse } from '../../interfaces/created-absence-response.interface';


@Component({
  selector: 'wkd-medical-certificate-type-resume',
  templateUrl: './medical-certificate-type-resume.component.html',
  styleUrls: ['./medical-certificate-type-resume.component.scss']
})
export class MedicalCertificateTypeResumeComponent {
  @Input() public typeData: TypeResumeData;
  @Input() public label = '';
  @Input() public creationData: CreatedAbsenceResponse;
  public absenceType = AbsenceType;
  constructor(private readonly dateService: ManipulationDatetimeService) { }
}
