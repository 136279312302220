import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

const amountNumber = 4;

function isRepeatedCharacter(word: string, amount = amountNumber) {
    if (!word || word.length < amount) {
      return false;
    }
    return amountDuplicatedCharacter(word) >= amount;
}

function amountDuplicatedCharacter(word) {
    let total = 0;
    for (const char of word) {
        if (char === word[0]) {
            total++;
        }
    }
    return total;
}

function isValidRegex(value: string, regex: RegExp) {
    const LIMIT = 2;
    return value.length > LIMIT ? regex.test(value) : false;
}

const num2 = 2;
@Injectable()
export class ValidatorFieldService {

    fieldTextValidator(control: AbstractControl): { [key: string]: boolean } | null {

        if (this.controlValue(control)) {
            const minChars = 4;
            const total   = control.value.length;
            let initial = 0;
            const limitChars = initial + minChars;
            let final = this.finalLimitChars(limitChars, total);

            do {
                const term = control.value.substring(initial, final++);
                if (this.invalidRepeatChars(term)) {
                    return { 'invalid_repeat_chars': true };
                }
                if (this.invalid_special_chars(term)) {
                    return { 'invalid_special_chars': true };
                }
                if (this.invalid_text(term)) {
                  return { 'invalid_text': true };
                }
                if (final > num2) {
                    initial++;
                }
            } while (initial <= total);
        }
        return null;
    }

  private controlValue(control: AbstractControl) {
    return control && control.value;
  }

  private finalLimitChars(limitChars: number, total) {
    return (limitChars < total) ? limitChars : total;
  }

  private invalid_text(term) {
    return isValidRegex(term, /([\\]+)/);
  }

  private invalid_special_chars(term) {
    return isValidRegex(term, /(\D)/) &&
      isValidRegex(term, /([\W]{4})/) &&
      !isValidRegex(term, /([a-z\u00C0-\u00ff A-Z])/);
  }

  private invalidRepeatChars(term) {
    return isValidRegex(term, /(\D)/) && isRepeatedCharacter(term);
  }

    requiredIfSelected(control: AbstractControl): { [key: string]: boolean } | null {
        if (control && control.value === '') {
            return { 'required_selected': true };
        }
        return null;
    }
}
