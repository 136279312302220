import { BaseResponse } from './../../interfaces/base-response.interface';
import { AbsenceResponse } from './../../interfaces/absence-response.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wkd-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss']
})
export class DataListComponent {
  @Input() data: BaseResponse<AbsenceResponse[]>;
  @Input() loading = true;
  @Output() itemsPageChange: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() selectedPageChange: EventEmitter<number> = new EventEmitter<number>(null);

  filterItems(limit: number) {
    this.itemsPageChange.emit(limit);
  }
  filterPage(pageNumber: number) {
    this.selectedPageChange.emit(pageNumber);
  }

  get displayElements(){
    return this.data && this.data._content && this.data._content.length > 0;
  }
}
