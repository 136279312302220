import { Injectable } from '@angular/core';
import { EnAccessType } from '../model/sample.model';

@Injectable()
export class SettingsService {

  private readonly refreshTimeNumber = 30000;

    public refreshTime: number = this.refreshTimeNumber;

    // ??
    // public HOST_ARSENAL = 'https://esbapi.isbanbr.dev.corp/humanresources/v2/';
    // public HOST_ARSENAL = 'https://esbapi.santander.com.br/humanresources/v2/'
    public static IP_HUB = '__hubhost__';
    public DOMAIN = 'data/';

    // others
    public static URL_PHOTO = '';
    // hosts-arsenal
    // public HOST = 'https://wkd.paas.isbanbr.dev.corp/absence_certifcate_collection/api/v1';
    // public HOST_AFASTAMENTO = 'https://wkd.paas.santanderbr.pre.corp/exp_collection_documents_inss_absense/v1';
    // public HOST_COLABORADORES = 'https://wkd.paas.isbanbr.dev.corp/absence_certifcate_collection/api/v1';

    // hosts-dev
    // public HOST = 'https://esbapi.isbanbr.dev.corp/absence_certifcate_collection/v1';
    // public HOST_AFASTAMENTO = 'https://esbapi.isbanbr.dev.corp/exp_collection_documents_inss_absense/v1';
    // public HOST_COLABORADORES = 'https://esbapi.isbanbr.dev.corp/absence_certifcate_collection/v1';
    // public HOST_TAA = 'https://esbapi.isbanbr.dev.corp/file-transfer-attachments/v3';

    // hosts-hk
    public HOST = 'https://esbapi.santander.com.br/absence_certifcate_collection/v1';
    public HOST_AFASTAMENTO = 'https://esbapi.santander.com.br/exp_collection_documents_inss_absense/v1';
    public HOST_COLABORADORES = 'https://esbapi.santander.com.br/absence_certifcate_collection/v1';
    public HOST_TAA = 'https://esbapi.santander.com.br/file-transfer-attachments/v3';
    public HOST_SEC = 'https://esbapi.santander.com.br/';

    // host-external
    // public HOST = 'https://esbapi.santander.com.br/absence_certifcate_collection/v1';
    // public HOST_AFASTAMENTO = 'https://esbapi.santander.com.br/exp_collection_documents_inss_absense/v1';
    // public HOST_COLABORADORES = 'https://esbapi.santander.com.br/absence_certifcate_collection/v1';
    // public HOST_TAA = 'https://esbapi.santander.com.br/file-transfer-attachments/v3';
    // public HOST_SEC = 'https://esbapi.santander.com.br/';

    public LG_HOST = '__lghost__';
    public LG_CREDENTIALS = {};

    public URL = '';
    public URI = { e9: {}, myWay: {}, t8: {} };

    public MOCK_ACTIVE = true;
    public CRYPT_ACTIVE = true;
    public IMPERSONATE_ACTIVE = true;
    public MOCK_USER_ACTIVE = false;

    //2e64ca3020d4013a8260005056a171c8
    // Externo
    public APP_KEY_EXTERNAL = '4717f2202b64013a8263005056a171c8';
    // Interno
    public APP_KEY_INTERNAL = 'ecb6a490b40301386bbf005056a18e37';

    //config.json
    public get ConfigJson(){
        return this.getAccessType() === EnAccessType.Internal?'assets/config.int.json':'assets/config.json'
    }


    public urlAbsenseIdDocuments = '/absense_processes/{absense_process_id}/documents';
    // URLS
    public GetReasons = this.HOST + '/absence_types/{absence_type_id}/reasons';
    public GetRequestType = this.HOST + '/absence_types/{absence_type_id}/request_types';
    public GetTrafficAccidents = this.HOST + '/absence_types/1/traffic_accidents';
    public GetParameters = this.HOST + '/absence_types/1/parameters';
    public GetStates = this.HOST + '/absence_types/1/states';
    public GetCrm = this.HOST + '/absence_types/1/crms/{crm_code}';
    public GetCid = this.HOST + '/absence_types/1/cids/{cid}';
    public GetRequests = this.HOST + '/absence_requests';

    public GetAbsenceProcess = this.HOST_AFASTAMENTO + '/absense_processes';
    public GetAbsenceProcessStages = this.HOST_AFASTAMENTO + '/absense_processes/{absense_process_id}/stages';
    public GetAbsenceProcessDetails = this.HOST_AFASTAMENTO + '/absense_processes/{absense_process_id}';
    public GetTem = this.HOST_AFASTAMENTO + '/absense_processes/{absense_process_id}/acceptance_term';
    public GetProcessDocumentList = this.HOST_AFASTAMENTO + this.urlAbsenseIdDocuments
    public PostProcessDocumentInformation = this.HOST_AFASTAMENTO + this.urlAbsenseIdDocuments
    public GetProcessDocumentInformation = this.HOST_AFASTAMENTO + '/absense_processes/{absense_process_id}/documents/{document_code}';
    public PostProcessDocument = this.HOST_AFASTAMENTO + this.urlAbsenseIdDocuments
    public AcceptTerm = this.HOST_AFASTAMENTO + '/absense_processes/{absense_process_id}/acceptance_term';
    public ConfirmationAcceptanceTerm = this.HOST_AFASTAMENTO + '/absense_processes/{absense_process_id}/acceptance_term/{term_id}';

    public PostRequests = this.HOST + '/absence_requests';
    public PutRequests = this.HOST + '/absence_requests/{absence_request_id}';
    public TaaAuthenticate = this.HOST_TAA + '/taa-authenticate';

    // Configurar Urls
    public authenticate: string = this.HOST_SEC + 'hr-security/v1/authenticate';
    public faqUrl = {
        1: './assets/mocks/faq.json',
        2: './assets/mocks/faq.json',
        3: this.HOST_AFASTAMENTO + '/absense_processes/1/faqs?_sort&_limit=1000&_offset=0',
    }

    // TAA Alias]
    private readonly ALIAS_TAA_INTERN = 'WKD_SP_IN_FNCOR';
    private readonly ALIAS_TAA_EXTERN = 'WKD_SP_EX_FNCOR';
    public BIRTH_CERTIFICATE = 'CERTIDAO_NASCIME';
    public MEDICAL_CERTIFICATE = 'ATESTADO_MEDICO';
    public COMPANY_CODE_TAA = '0033';
    public CLIENT_NODE_TAA = 'WKD';
    public get ALIAS_TAA() {
        return this.getAccessType() === EnAccessType.External? this.ALIAS_TAA_EXTERN:this.ALIAS_TAA_INTERN;
    }

    // Employee Roles
    public ROLE_EMPLOYEE = { id: '1', description: 'employee' };
    public ROLE_MANAGER = { id: '2', description: 'manager' };
    public ROLE_VP = { id: '3', description: 'vp' };
    public ROLE_MNG_EPY = { id: '6', description: 'vp' };
    public ROLE_DELEGATED_EMPLOYEE = { id: '4', description: 'delegated' };
    public ROLE_DELEGATED_MANAGER  = { id: '5', description: 'delegated' };
    public ROLE_DELEGATED_MNG_EPY = { id: '7', description: 'delegated' };
    public ROLE_DELEGATED_VP = { id: '8', description: 'delegated' };

    // Status Nominee Ativo
    public STATUS_ACTIVE = { id: 'A', description: 'active' };

    // Request Release types
    public REQUEST_OVERTIME = { id: '1', description: 'overtime' };
    public REQUEST_EXCEPTION = { id: '2', description: 'exception' };
    public REQUEST_OFFLINE_PASSWORD_EXCEPTION = { id: '3', description: 'offline_exception' };

    // Request Release status
    public REQUEST_STATUS_PENDING = { id: 'P', description: 'Pendente' }; // 0
    public REQUEST_STATUS_APPROVED = { id: 'A', description: 'Aprovado' }; // 1
    public REQUEST_STATUS_DISAPPROVED = { id: 'D', description: 'Reprovado' }; // 2
    public REQUEST_STATUS_DISAPPROVED_ = { id: 'R', description: 'Reprovado' }; // 2
    public REQUEST_STATUS_CANCELED = { id: 'C', description: 'Cancelado' }; // 3
    public REQUEST_STATUS_CANCELED_ = { id: 'S', description: 'Cancelado' }; // 3
    public REQUEST_STATUS_EXPIRED = { id: 'E', description: 'expired' }; // 4
    public REQUEST_STATUS_ALL = { id: '', description: 'all status' }; // 5

    public GETCALLRELEASE_BY_OWNER = '0';
    public GETCALLRELEASE_BY_APPROVER = '1';
    public GETCALLRELEASE_BY_REQUESTER = '2';

    // time extension assign
    public TERM_EXTENSION_ASSIGN = { id: '1', description: 'assinada' };

    public getUrl(uri: string, isOther?: boolean, args?: Array<any>): string {
        if (isOther) {
            let url = '';
            if (undefined !== args && args.length > 0) {
                url = url + args.join('&');
            }
            return url;
        } else {
            let url = this.URL + this.URI.t8[uri];

            if (args) {
                args.forEach((v, k) => {
                    url += v;
                    if (args.length - 1 > k) {
                        url += '/';
                    }
                });
            }
            // url = url+ '?gw-app-key='+ this.APP_KEY
            return url;
        }
    }

    public getAppKey() {
        if (this.getAccessType() === EnAccessType.Internal) {
            return this.APP_KEY_INTERNAL;
        } else {
            return this.APP_KEY_EXTERNAL;
        }
    }

    public getAccessType() {
        return EnAccessType.Internal;
    }

    public getZupURL() {
        return this.HOST_SEC.substr(0,this.HOST_SEC.length-1);
    }
}

