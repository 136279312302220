export class Sample {
    id: number;
    name: string;

    IsValid() {
        return this.id > 0;
    }
}

export interface FileBase64 {
    name: string;
    size: number;
    type: string;
    data: string;
}

export enum EnAccessType {
    Internal = 'APP_INTERNAL',
    External = 'APP_EXTERNAL'
}

// Types Nominee -> para não dar erro: Circular dependency detected
export enum TypeNomineePeriod {
    NONIMEE_PERMANENTY = '2',
    NONIMEE_PERIOD     = '1'
} 