import { SettingsService } from './../../services/settings.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from '../../interfaces/base-response.interface';
import { FAQ } from '../../interfaces/faq.interface';
import { AbsenceType } from '../../enum/absence-type.enum';
import { HubConnectorService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(private readonly httpClient: HubConnectorService,
              private readonly settingService: SettingsService){}
   getFaq(absenceType: AbsenceType): Observable<BaseResponse<FAQ[]>>{
    return this.httpClient.get<BaseResponse<FAQ[]>>(this.settingService.faqUrl[absenceType]);
  }
}
