import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'wkd-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent{
  @Input() mainTitle: string;
  @Input() welcomeMessage: string;
  @Input() displayHello = true;
}

