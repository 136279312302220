import { AppRoutes } from './enum/routes.enum';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesParams } from './model/abstract-route';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  title = 'wkd-spa-wkd-afastados';
  constructor (private readonly router: Router) {

  }
  ngOnInit() {
    const navigateRoute = RoutesParams[localStorage.getItem('md')] || AppRoutes.BASE;
    this.router.navigate([navigateRoute],  {state: {redirect: true}});
  }

}
