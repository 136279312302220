import { Injectable } from '@angular/core';
import { stringify } from 'querystring-es3';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SettingsService } from '.';
import { ApiBoolean } from '../enum/api-boolean.enum';
import { BaseResponse } from '../interfaces/base-response.interface';
import { Filter } from '../interfaces/filter.interface';
import { ReasonResponse } from '../interfaces/reason-response.interface';
import { AuthService } from './auth.service';
import { HubConnectorService } from './hubConnector.service';

@Injectable({
  providedIn: 'root'
})
export class AbsenceReasonService {

  constructor(public readonly hubService: HubConnectorService,
    public readonly authService: AuthService,
    public settingService: SettingsService) { }

  public getReasons(filter: Filter): Observable<BaseResponse<ReasonResponse[]>> {
    filter.employeeId = this.authService.getAuthId();
    const params = stringify(filter);
    const url = this.settingService.GetReasons.replace('{absence_type_id}', filter.requestTypeId.toString()) + '?' + String(params);
    return this.hubService.get<BaseResponse<ReasonResponse[]>>(url)
    .pipe(
      catchError(err => {
        const response: BaseResponse<ReasonResponse[]> = {
          _pageable: {
            _limit: 30,
            _offset: 0,
            _pageNumber: 0,
            _pageElements: 0,
            _totalPages: 5,
            _totalElements: 0
          },
          _content: []
        }
        return of(response);
      }),
    );
  }
}
