import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wkd-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(()=> CommentComponent),
      multi: true
    }
  ]
})
export class CommentComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean;


  private _value = '';
  public get value() : string {
    return this._value;
  }
  public set value(v : string) {
    this._value = v;
    this.onChange(this._value);
  }

  constructor() { }
  onChange= (value: string)=>{};
  onTouched= ()=>{};

  ngOnInit() {
  }

  writeValue(obj: string): void {
    this.value = obj;
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }



}
