const num9 = 9;
export class Functions {

    static pad_zero(numero, length = num9) {
        let str = `${numero}`;

        while (str.length < length) {
            str = `0${str}`;
        }

        return str;
    }

    static strToBoolean(value: string|boolean): boolean {
        return (value === 'true' || value === true);
    }
}

