import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DssAccordionModule } from '@dss/components/accordion';
import { DssAlertBannerModule } from '@dss/components/alert-banner';
import { DssAlertToastModule } from '@dss/components/alert-toast';
import { DssBreadcrumbModule } from '@dss/components/breadcrumb';
import { DssButtonModule } from '@dss/components/button';
import { DssCardModule } from '@dss/components/card';
import { DssContainerModule } from '@dss/components/container';
import { DssDatepickerModule } from '@dss/components/datepicker';
import { DssDialogModule } from '@dss/components/dialog';
import { DssDividerModule } from '@dss/components/divider';
import { DssDropdownModule } from '@dss/components/dropdown';
import { DssFormFieldModule } from '@dss/components/form-field';
import { DssHeaderModule } from '@dss/components/header';
import { DssIconModule } from '@dss/components/icon';
import { DssInputNumberModule } from '@dss/components/input-number';
import { DssListModule } from '@dss/components/list';
import { DssLogoModule } from '@dss/components/logo';
import { DssPaginationModule } from '@dss/components/pagination';
import { DssProgressStepModule } from '@dss/components/progress-step';
import { DssRadioGroupModule } from '@dss/components/radio-group';
import { DssStatusIconModule } from '@dss/components/status-icon';
import { DssTypographyModule } from '@dss/components/typography';
import { DssUploadModule } from '@dss/components/upload';
import { DssCheckboxModule } from '@dss/components/checkbox';

@NgModule({

    imports:[
        CommonModule,
    ],
    exports:[
        DssLogoModule,
        DssCardModule,
        DssTypographyModule,
        DssIconModule,
        DssHeaderModule,
        DssBreadcrumbModule,
        DssButtonModule,
        DssAccordionModule,
        DssContainerModule,
        DssPaginationModule,
        DssStatusIconModule,
        DssProgressStepModule,
        DssFormFieldModule,
        DssDropdownModule,
        DssDatepickerModule,
        DssRadioGroupModule,
        DssInputNumberModule,
        DssListModule,
        DssDialogModule,
        MatDialogModule,
        DssAlertToastModule,
        DssAlertBannerModule,
        DssUploadModule,
        DssDividerModule,
        DssCheckboxModule
    ]
})
export class DssComponentsModule{}