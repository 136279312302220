import { Component } from '@angular/core';

@Component({
    selector: 'blank',
    templateUrl: './blank.component.html'
})

export class BlankComponent {

}
