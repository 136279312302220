import { CriptoModule, HubConnectorModule, JsonConfigModule } from '@afe/base-geral';
import { SSOInterceptorModule, SSOnterceptorConfig } from '@afe/http-interceptors/sso';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DownloadService, FileTransferModule, UploadService } from '@taa/file-transfer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ConfirmRedirectDialogComponent } from './components/confirm-redirect-dialog/confirm-redirect-dialog.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { AngularMaterialModule } from './modules/angularMaterial.module';
import { DssComponentsModule } from './modules/dss-components.module';
import { LocalStorageModule } from './modules/local-storage.module';
import { SantanderAuthModule } from './modules/santander-auth.module';
import { AuthenticationErrorComponent } from './pages/authentication-error/authentication-error.component';
import { ExpiredSessionComponent } from './pages/expired-session/expired-session.component';
import { GenericErrorPageComponent } from './pages/generic-error-page/generic-error-page.component';
import { HomeComponent } from './pages/home/home.component';
import { ListBaseComponent } from './pages/list-base/list-base.component';
import { NotAllowedComponent } from './pages/not-allowed/not-allowed.component';
import {
  BreakpointService,
  EmployeeService,
  HubConnectorService,
  ManipulationDatetimeService,
  SettingsService,
  StorageService,
  ValidatorFieldService
} from './services';
import { AbsenceProcessService } from './services/absence-process.service';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';
import { AuthenticateService } from './services/authenticate.service';
import { UserService } from './services/user.service';
import { AbsenceProcessMockService } from './test/mock/absence-process-mock.service';
import { NotAllowedEstagComponent } from './pages/not-allowed-estag/not-allowed-estag.component';


export const urlsSSO: SSOnterceptorConfig = {
  urlsSSO: [
    'https://wastfcdvlbr01.bs.br.bsch/sso-v4/authenticate/internal-full-v4?gw-app-key=ecb6a490b40301386bbf005056a18e37'
  ],
};
export const rootConfig = {
  hostHub: (new SettingsService()).getZupURL(),
  hostTAA: 'https://taa.santander.com.br',
  appKey: (new SettingsService()).getAppKey(),
  urlHub: (new SettingsService()).getZupURL(),
  urlTAA: 'https://taa.santander.com.br',
  enableCriptography: true,
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ExpiredSessionComponent,
    AuthenticationErrorComponent,
    GenericErrorPageComponent,
    NotAllowedComponent,
    NotAllowedEstagComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularMaterialModule,
    HubConnectorModule.forRoot('url_hub_sso'),
    JsonConfigModule.forRoot([(new SettingsService()).ConfigJson]),
    SSOInterceptorModule.forRoot(urlsSSO),
    CriptoModule.forRoot('url_encryption', 'system_code'),
    // ErrorHandlingModule,
    // LogModule.forRoot('url_log'),
    ComponentsModule,
    LocalStorageModule.forRoot(),
    SantanderAuthModule.forRoot(new SettingsService()),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/translate/', '.json'),
        deps: [HttpClient]
      }
    }),
    CdkTableModule,
    BrowserAnimationsModule,
    DssComponentsModule,
    FileTransferModule.forRoot(rootConfig),
    ComponentsModule
  ],
  entryComponents: [
    AppComponent,
    SpinnerComponent,
    ConfirmRedirectDialogComponent
  ],
  exports: [
    ComponentsModule,
    TranslateModule,
    ListBaseComponent
  ],
  providers: [
    AppService,
    AuthenticateService,
    AuthService,
    SettingsService,
    StorageService,
    UserService,
    HubConnectorService,
    EmployeeService,
    ManipulationDatetimeService,
    DownloadService,
    UploadService,
    BreakpointService,

    ValidatorFieldService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    AbsenceProcessService
    // {
    //   provide: AbsenceProcessService,
    //   useClass: AbsenceProcessMockService
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
