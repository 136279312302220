import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wkd-paginator',
  templateUrl: './wkd-paginator.component.html',
  styleUrls: ['./wkd-paginator.component.scss']
})
export class WkdPaginatorComponent {
  @Input() public pageCount = 0;
  @Input() public  optionDefault = '30';
  constructor() { }

  @Output() itemsPageChange: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() selectedPageChange: EventEmitter<number> = new EventEmitter<number>(null);

  filterItems(limit: number) {
    this.itemsPageChange.emit(limit);
  }

  filterPage(pageNumber: number) {
    this.selectedPageChange.emit(pageNumber);
  }
}
