import { ListBaseComponent } from './../pages/list-base/list-base.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  BlankComponent
} from '.';
import { AngularMaterialModule } from '../modules/angularMaterial.module';
import {
  DecryptPipe,
  MinutesToHoursPipe,
  MonthNumberToStringPipe,

  WeekDateVisiblePipe
} from '../pipes';
import { InfoComponent } from './info/info.component';
import { HeaderComponent } from './header/header.component';
import { DssComponentsModule } from '../modules/dss-components.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';
import { DataListComponent } from './data-list/data-list.component';
import { EmptyDataMessageComponent } from './empty-data-message/empty-data-message.component';
import { ListStatusComponent } from './list-status/list-status.component';
import { FaqComponent } from './faq/faq.component';
import { MedicalCertificateTypeSelectComponent } from './medical-certificate-type-select/medical-certificate-type-select.component';
import { MedicalCertificateTypeResumeComponent } from './medical-certificate-type-resume/medical-certificate-type-resume.component';
import { MedicalCertificateExtensionComponent } from './medical-certificate-extension/medical-certificate-extension.component';
import { DoctorDataComponent } from './doctor-data/doctor-data.component';
import { StateSelectComponent } from './state-select/state-select.component';
import { ContainerBoxComponent } from './container-box/container-box.component';
import { CrmInputComponent } from './crm-input/crm-input.component';
import { CrmAndNameComponent } from './crm-and-name/crm-and-name.component';
import { TrafficAccidentSelectComponent } from './traffic-accident-select/traffic-accident-select.component';
import { CidFormComponent } from './cid-form/cid-form.component';
import { CidInputComponent } from './cid-input/cid-input.component';
import { ConfirmRedirectDialogComponent } from './confirm-redirect-dialog/confirm-redirect-dialog.component';
import { FileResumeComponent } from './file-resume/file-resume.component';
import { QuestionsResumeComponent } from './questions-resume/questions-resume.component';
import { CidsResumeComponent } from './cids-resume/cids-resume.component';
import { FormResumeComponent } from './form-resume/form-resume.component';
import { ReasonSelectComponent } from './reason-select/reason-select.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { UploadedFilesComponent } from './uploaded-files/uploaded-files.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentsResumeComponent } from './documents-resume/documents-resume.component';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MainComponent } from '../pages/main/main.component';
import { TypeFormComponent } from './type-form/type-form.component';
import { CommentComponent } from './comment/comment.component';
import { TitleLabelComponent } from './title-lablel/title-label.component';
import { WkdPaginatorComponent } from './wkd-paginator/wkd-paginator.component';
import { FileComponent } from './file/file.component';
import { FileTesteComponent } from './file-teste/file-teste.component';

@NgModule({
  declarations: [
    BlankComponent,
    InfoComponent,
    MinutesToHoursPipe,
    WeekDateVisiblePipe,
    MainComponent,
    MonthNumberToStringPipe,
    DecryptPipe,
    HeaderComponent,
    BreadcrumbComponent,
    WelcomeMessageComponent,
    ListBaseComponent,
    DataListComponent,
    EmptyDataMessageComponent,
    ListStatusComponent,
    FaqComponent,
    MedicalCertificateTypeSelectComponent,
    MedicalCertificateTypeResumeComponent,
    MedicalCertificateExtensionComponent,
    DoctorDataComponent,
    StateSelectComponent,
    ContainerBoxComponent,
    CrmInputComponent,
    CrmAndNameComponent,
    TrafficAccidentSelectComponent,
    CidFormComponent,
    CidInputComponent,
    ConfirmRedirectDialogComponent,
    FileResumeComponent,
    QuestionsResumeComponent,
    CidsResumeComponent,
    FormResumeComponent,
    ReasonSelectComponent,
    UploadFileComponent,
    UploadedFilesComponent,
    DocumentsComponent,
    DocumentsResumeComponent,
    ConfirmDeleteDialogComponent,
    SpinnerComponent,
    TypeFormComponent,
    CommentComponent,
    TitleLabelComponent,
    WkdPaginatorComponent,
    FileComponent,
    FileTesteComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    DssComponentsModule,
    TranslateModule
  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerGestureConfig
  }],
  exports: [
    AngularMaterialModule,
    BlankComponent,
    InfoComponent,
    HeaderComponent,
    BreadcrumbComponent,
    WelcomeMessageComponent,
    ListBaseComponent,
    DataListComponent,
    MedicalCertificateTypeSelectComponent,
    MedicalCertificateTypeResumeComponent,
    MedicalCertificateExtensionComponent,
    DoctorDataComponent,
    StateSelectComponent,
    ContainerBoxComponent,
    TrafficAccidentSelectComponent,
    CidFormComponent,
    ConfirmRedirectDialogComponent,
    FormResumeComponent,
    ReasonSelectComponent,
    UploadedFilesComponent,
    UploadFileComponent,
    DocumentsComponent,
    SpinnerComponent,
    MainComponent,
    TypeFormComponent,
    CommentComponent,
    FaqComponent,
    TitleLabelComponent,
    WkdPaginatorComponent,
    EmptyDataMessageComponent,
    ListStatusComponent,
    FileComponent,
    FileTesteComponent

  ]
})
export class ComponentsModule { }
