import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRoutes } from '../enum/routes.enum';


@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(public router: Router,
        public dialog: MatDialog) {

        }
    public handleError(err: HttpErrorResponse): Observable<any> {
      const unauthorizedStatus = 401;
      const okStatus = 200;
        if(err.status === unauthorizedStatus) {
            this.router.navigate([AppRoutes.AUTHENTICATION_ERROR],  {state: {redirect: true, external: true}, queryParams: {'redirect': AppRoutes.BASE}});
            this.dialog.closeAll();
            return throwError(err);

        }
        if (err.status !== okStatus) {
            this.router.navigate([AppRoutes.FAIL],  {state: {redirect: true}});
            this.dialog.closeAll();

            return throwError(err);
        }
        return of(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(catchError(x => this.handleError(x)));
    }


}
