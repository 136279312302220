import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wkd-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements OnInit {
  @Input() open: boolean;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() confirmEvent: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor() { }

  ngOnInit() {
  }

  confirm(){
    this.confirmEvent.emit(true);
    this.close();
  }

  close(){
    this.open = false;
    this.openChange.emit(this.open);
  }
}
