import { DocumentInfo } from './../../interfaces/document-info.interface';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wkd-documents-resume',
  templateUrl: './documents-resume.component.html',
  styleUrls: ['./documents-resume.component.scss']
})
export class DocumentsResumeComponent implements OnInit {
  @Input() public label = 'Arquivos anexados';
  @Input() public documents: DocumentInfo[] = [];
  constructor() { }

  ngOnInit() {
  }

}
