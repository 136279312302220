import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring-es3';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HubConnectorService, SettingsService } from '.';
import { BaseResponse } from '../interfaces/base-response.interface';
import { Filter } from '../interfaces/filter.interface';
import { TypeResponse } from '../interfaces/type-response.interface';
import { AuthService } from './auth.service';
import { AppRoutes } from '../enum/routes.enum';
import { BaseDropDown } from '../../../src/app/interfaces/base-dropwdown.interface';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AbsenceTypeService {
  public types: BaseDropDown[] = []
  constructor(public readonly hubService: HubConnectorService,
    public readonly router: Router,
    public readonly authService: AuthService,
    public settingService: SettingsService) { }


  public getTypes(filter: Filter): Observable<BaseResponse<TypeResponse[]>> {
    filter.employeeId = this.authService.getAuthId();
    const params = stringify(filter);
    const url = this.settingService.GetRequestType.replace('{absence_type_id}', filter.requestTypeId.toString()) + '?' + String(params);
    return this.hubService.get<BaseResponse<TypeResponse[]>>(url)
    .pipe(
      tap((response) => {
        if (response._content.length === 0){
          this.router.navigate([AppRoutes.PROFILE_ERROR_ESTAG], { state: { redirect: true }, queryParams: { 'redirect': this.router.url.includes(AppRoutes.HEALTH_CERTIFICATE) ? AppRoutes.HEALTH_CERTIFICATE.toString() : AppRoutes.FAMILY_CERTIFICATE.toString() } });
        }
      }),
      catchError(err => {
        const response: BaseResponse<TypeResponse[]> = {
          _pageable: {
            _limit: 30,
            _offset: 0,
            _pageNumber: 0,
            _pageElements: 0,
            _totalPages: 5,
            _totalElements: 0
          },
          _content: []
        }
        return of(response);
      }),
    );


  }
  public blockTrainee(){
    this.getTypes({
      requestTypeId: 1,
      _sort: null,
      _limit: 1000,
      _offset: 0
    })
      .pipe(
        take(1),
        map((response) => {
          return response._content.map((reason) => {
            return {
              value: {
                ...reason,
              },
              viewValue: reason.description
            }
          });

        })
      )
      .subscribe(
        (response) => {
          this.types = this.sortTypes(response);
        }
      )
  }
  private sortTypes(types: BaseDropDown[]): BaseDropDown[] {
    return types.sort((a, b) => {
        return a.value.nrSort < b.value.nrSort ? -1 : a.value.nrSort > b.value.nrSort ? 1 : 0;
      });
  }


}
