import { Injectable } from '@angular/core';
import { stringify } from 'querystring-es3';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { HubConnectorService } from './hubConnector.service';
import { SettingsService } from './settings.service';
import { BaseResponse } from '../interfaces/base-response.interface';
import { CidQuery } from '../interfaces/cid-query.interface';
import { CidResponse } from '../interfaces/cid-response.interface';

@Injectable({
  providedIn: 'root'
})
export class CidService {

  constructor(public readonly hubService: HubConnectorService,
    public settingService: SettingsService) { }


  getCid(filter: CidQuery): Observable<CidResponse> {
    const params = stringify(filter);
    const url = this.settingService.GetCid.replace('{cid}', filter.cid_code.toUpperCase()) + '?' + String(params);
    return this.hubService.get<BaseResponse<CidResponse[]>>(url)
    .pipe(
      catchError(err => {
        const response: BaseResponse<CidResponse[]> = {
          _pageable: {
            _limit: 30,
            _offset: 0,
            _pageNumber: 0,
            _pageElements: 0,
            _totalPages: 5,
            _totalElements: 0
          },
          _content: [
            {}
          ]
        }
        return of(response);
      }),
      map((response) => {
        return response._content[0]
      })
    );

  }
}