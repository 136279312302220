import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wkd-title-label',
  templateUrl: './title-label.component.html',
  styleUrls: ['./title-label.component.scss']
})
export class TitleLabelComponent implements OnInit {
  @Input() public subject = '';
  @Input() public label = '';
  @Input() public bold = true;
  @Input() public placeHolder = false;
  constructor() { }

  ngOnInit() {
    /* istanbul ignore else */
    if(this.placeHolder) {
      this.label = "*";
      this.subject = "*";
    }
  }

}
