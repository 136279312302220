import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { AppRoutes } from '../enum/routes.enum';


@Injectable()
export class AuthenticateService implements CanActivate, CanActivateChild {
    constructor(
        public readonly authService: AuthService,
        public readonly router: Router
    ) { }

    canActivate(): Observable<boolean> {
        return this.authenticate();
    }

    canActivateChild(): Observable<boolean> {
        return this.authenticate();
    }

    authenticate(): Observable<boolean> {
        return this.authService.authenticate()
        .pipe(map(user => {
            if (Boolean(user)) {
                return true;
            } else {
                throw new Error(user.message);
            }
        }))

        .pipe(
            catchError(error => {
                this.router.navigate([AppRoutes.AUTHENTICATION_ERROR], { skipLocationChange: true, state: {redirect: true, external: true} });
                const errorMessage = (error.error instanceof ErrorEvent) ? error.error.message : error.error;
                return throwError(errorMessage || 'Erro em authenticate.');
            })
        );
    }

}
