import { Injectable } from '@angular/core';
import { DownloadFinalResponse, UploadServiceResponse } from '@taa/file-transfer';
import { stringify } from 'querystring-es3';
import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { HubConnectorService, SettingsService } from '.';
import { AbsenceProcessDocument } from '../interfaces/absence-process-document.interface';
import { AbsenceProcessUploadDocumentResponse } from '../interfaces/absence-process-upload-document-response.interface';
import { AbsenceProcess } from '../interfaces/absence-process.interface';
import { AcceptTermResponse } from '../interfaces/accept-term-response.interface';
import { BaseResponse } from '../interfaces/base-response.interface';
import { AbsenceProcessDocumentInformation } from '../interfaces/document-download.interface';
import { Filter } from '../interfaces/filter.interface';
import { ProcessStage } from '../interfaces/process-stage.interface';
import { TermResponse } from '../interfaces/term-response.interface';
import { AuthService } from './auth.service';
import { FileService } from './file.service';
import { WkdEncryptionService } from './wkd-encryption.service';
import { InssDocumentStatus } from '../enum/inss-document-status.enum';
import { AbsenceProcessUploadDocumentRequest } from '../interfaces/absence-process-upload-document-request.interface';
import { UploadServiceResponse2 } from '../model/upload-service-response2';

const stringAbsenceProcessId = '{absense_process_id}';

@Injectable({
  providedIn: 'root'
})


export class AbsenceProcessService {

  constructor(public readonly hubService: HubConnectorService,
              public readonly wkdEncrypt: WkdEncryptionService,
              public readonly fileService: FileService,
              public readonly authService: AuthService,
              public readonly settingService: SettingsService) { }

  public getAbsenceProcess(filter: Filter): Observable<BaseResponse<AbsenceProcess[]>> {
    filter.employeeId = this.authService.getAuthId();
    const params = stringify(filter);
    const url = this.settingService.GetAbsenceProcess + '?' + String(params);
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.get<BaseResponse<AbsenceProcess[]>>(url)
                }),
              )

  }

  public getAbsenceProcessDetails(absenceProcessId: string | number): Observable<AbsenceProcess> {
    const url = this.settingService.GetAbsenceProcessDetails.replace(stringAbsenceProcessId, absenceProcessId.toString());
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.get<AbsenceProcess>(url)
                }),
                switchMap(async (response)=>{
                  await this.wkdEncrypt.decryptObjectByReference(response, ['processId','employeeName','managerId','managerName','startDate','endDate','type','stage','expertiseDate','expertiseResult','medicalDischargeDate','examReturnDate','examResults','preInstructions','title','instructions','infos','postInstructions']);
                  return response
                }),
              )

  }

  public getTerm(absenceProcessId: number): Observable<TermResponse> {
    const filter: Filter = {
          _sort: null,
          _limit: 1,
          _offset: 0
        }
    const params = stringify(filter);

    const url = this.settingService.GetTem.replace(stringAbsenceProcessId, absenceProcessId.toString())+ '?' + String(params);
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.get<BaseResponse<TermResponse[]>>(url)
                }),
                switchMap(async (response)=>{
                  return response._content[0]
                }),
              )
  }

  public processDocumentsType(absenceProcessId: string | number, documentStatus: number | InssDocumentStatus): Observable<BaseResponse<AbsenceProcessDocument[]>> {
    const filter: Filter = {
                            _sort: null,
                            _limit: 1000,
                            _offset: 0,
                            document_status: documentStatus
                          }
    const params = stringify(filter);

    const url = this.settingService.GetProcessDocumentList.replace(stringAbsenceProcessId, absenceProcessId.toString())+ '?' + String(params);
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.get<BaseResponse<AbsenceProcessDocument[]>>(url)
                }),
                switchMap(async (response)=>{
                  await this.wkdEncrypt.decryptObjectByReference(response._content, ['code','name','description','instruction','isRequired']);
                  return response
                }),
              )

  }

  public acceptTerm(absenceProcessId: string | number): Observable<AcceptTermResponse> {
    const url = this.settingService.AcceptTerm.replace(stringAbsenceProcessId, absenceProcessId.toString());
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.post<AcceptTermResponse>(url, undefined)
                }),
                switchMap(async (response)=>{
                  await this.wkdEncrypt.decryptObjectByReference(response, ['newId', 'message',  'signatureData']);
                  return response
                }),
              )
  }
  public confirmationAcceptanceTerm(absenceProcessId: string | number, termId: number): Observable<AcceptTermResponse> {
    const url = this.settingService.ConfirmationAcceptanceTerm.replace(stringAbsenceProcessId, absenceProcessId.toString()).replace('{term_id}', termId.toString());
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.put<AcceptTermResponse>(url, undefined)
                }),
                switchMap(async (response)=>{
                  await this.wkdEncrypt.decryptObjectByReference(response, ['requestType', 'id',  'description']);
                  return response
                }),
              )
  }

  public proccessFileDown(absenceProcessId: string | number, fileCode: string) {
    let fileName = '';
    return this.getDocumentInformation(absenceProcessId, fileCode)
                .pipe(
                  switchMap((response) => {
                    fileName = response.fileName;
                    return this.fileService
                                .download(response.cftFileCode)
                  }),
                  tap((taaFileResponse: DownloadFinalResponse) => {
                    const a = document.createElement("a");
                    a.href = `data:${taaFileResponse.mimeType};base64,${taaFileResponse.base64}`;
                    a.download = fileName;
                    a.click();
                  } )
                )
  }

  // public getDocumentInformation(absenceProcessId: string | number, documentCode: string): Observable<AbsenceProcessDocumentInformation> {
  //   const url = this.settingService.GetProcessDocumentInformation.replace(stringAbsenceProcessId, absenceProcessId.toString()).replace('{document_code}', documentCode);
  //   return  from(this.wkdEncrypt.getKeys())
  //             .pipe(
  //               switchMap(()=>{
  //                 return this.hubService.get<AbsenceProcessDocumentInformation>(url)
  //               }),
  //               tap((response) => {
  //                 var a = document.createElement("a");
  //                 a.href = `data:application/octet-stream;base64,${response.cftFileCode}`;
  //                 a.download = response.fileName;
  //                 a.click();
  //               } )
  //             )
  // }

  public getDocumentInformation(absenceProcessId: string | number, documentCode: string): Observable<AbsenceProcessDocumentInformation> {
    const url = this.settingService.GetProcessDocumentInformation.replace(stringAbsenceProcessId, absenceProcessId.toString()).replace('{document_code}', documentCode);
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.get<AbsenceProcessDocumentInformation>(url)
                }),
              )
  }


  public getStages(absenceProcessId: string | number): Observable<BaseResponse<ProcessStage[]>> {
    const filter: Filter = {
      _sort: null,
      _limit: 1000,
      _offset: 0
    }
    const params = stringify(filter);
    const url = this.settingService.GetAbsenceProcessStages.replace(stringAbsenceProcessId, absenceProcessId.toString()) + '?' + String(params);
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.get<BaseResponse<ProcessStage[]>>(url)
                }),
              )

  }
  public uploadFile(uploadInformation: {
    absenceProcessId: number,
    file: File,
    algumObjetoParaSerEnviadoNoBody: AbsenceProcessUploadDocumentRequest
  }): Observable<AbsenceProcessUploadDocumentResponse> {
    const url = this.settingService.PostProcessDocumentInformation.replace(stringAbsenceProcessId, uploadInformation.absenceProcessId.toString());
    const fileName: string = uploadInformation.file.name;
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(() => {
                  return this.fileService.upload(uploadInformation.file);
                }),
                switchMap((response: UploadServiceResponse2)=>{
                  // usar o response para setar o gnId no objeto que vai no post
                  if(uploadInformation.algumObjetoParaSerEnviadoNoBody !== undefined && uploadInformation.algumObjetoParaSerEnviadoNoBody !== null) {
                    uploadInformation.algumObjetoParaSerEnviadoNoBody.cftFileCode = response.fileCode.gnId;
                    uploadInformation.algumObjetoParaSerEnviadoNoBody.fileName = fileName;
                  }
                  return this.hubService.post<AbsenceProcessUploadDocumentResponse>(url, uploadInformation.algumObjetoParaSerEnviadoNoBody)
                }),

              )
  }

  public processDocumentsTypeList(absenceProcessId: string | number, documentStatus: number | InssDocumentStatus): Observable<BaseResponse<AbsenceProcessDocument[]>> {

    const filter: Filter = {
      _sort: null,
      _limit: 1000,
      _offset: 0,
      document_status: documentStatus
    }
    const params = stringify(filter);

    const url = this.settingService.GetProcessDocumentList.replace(stringAbsenceProcessId, absenceProcessId.toString())+ '?' + String(params);

    return from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                    return this.hubService.get<BaseResponse<AbsenceProcessDocument[]>>(url)
                  }),
                  switchMap(async (response)=>{
                    await this.wkdEncrypt.decryptObjectByReference(response._content, ['code','name','description','instruction','isRequired']);
                    return response;
                  })
              )

  }
}
