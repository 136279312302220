import { DocumentInfo } from './../../interfaces/document-info.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const num2000 = 2000;
@Component({
  selector: 'wkd-uploaded-files',
  templateUrl: './uploaded-files.component.html',
  styleUrls: ['./uploaded-files.component.scss']
})
export class UploadedFilesComponent implements OnInit {
  @Input() public documents: DocumentInfo[] = [];
  @Output() public documentsChange: EventEmitter<DocumentInfo[]> = new EventEmitter<DocumentInfo[]>();
  public documentToRemove: number;
  public open= false;
  public maxlength = num2000
  constructor() { }

  ngOnInit() {
  }

  fileSize(bytes: number){
    if (bytes === 0){
      return '0 Bytes';
    }

    const k = 1024;
    const dm = 0;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  openDialog(index: number){
    this.documentToRemove = index;
    this.open = true;
  }
  removeDocument(index: number){
    this.documents.splice(index, 1);
    this.documentsChange.emit(this.documents);
  }
}
