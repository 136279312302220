import { HubConnectorComponent } from '@afe/base-geral';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring-es3';
import { Observable, Subject } from 'rxjs';
import { map, debounceTime, delay, takeUntil, catchError } from 'rxjs/operators';
import { AbsenceType } from '../enum/absence-type.enum';
import { Filter } from '../interfaces/filter.interface';
import { SettingsService } from './settings.service';



/**
 * Service to subscribe hubConnectorComponent
 *
 * @author adurazzo
 * @since 03/01/2018
 * @Use  to add custom headers
 *
 */
@Injectable({
    providedIn: 'root'
})
export class HubConnectorService {
    protected static TYPE_CONNECTION_KEY = 'typeConnection';
    private readonly headers = new HttpHeaders();
    public subject$: Subject<unknown> = new Subject();
    private readonly destroy$: Subject<any> = new Subject();
    private readonly opts: { headers: HttpHeaders, params} = {
        headers: new HttpHeaders(),
        params: undefined
    };

    constructor(
        private readonly settingService: SettingsService,
        private readonly hub: HubConnectorComponent
    ) {
        this.subject$
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(this.settingService.refreshTime)

            )
            .subscribe(() => {

                this.keepAlive();
            },
            );
    }

    setLocalMock() {
        this.headers.append(HubConnectorService.TYPE_CONNECTION_KEY, 'localMock');
        this.opts.headers = this.headers;
    }

    authenticate(): Observable<{}> {
        return this.hub.authenticate();
    }
    getJson(url: string, options?): Observable<unknown> {
        this.opts.params += options;
        url = this.setKeyToUrl(url);

        return this.hub.getJson(url, this.opts);
    }
    get<T>(url: string, options?): Observable<T> {
        this.opts.params += options;
        this.subject$.next(null);
        url = this.setKeyToUrl(url);
        return this.hub
            .get(url, this.opts)
            .pipe(
                catchError(err => {
                    this.destroy$.next();
                    this.destroy$.complete();
                    return err
                }),
                map((reponse: HttpResponse<T>) => reponse.body));
    }
    postJson<T>(url: string, body:any, options?: any): Observable<T> {
        url = this.setKeyToUrl(url);
        this.opts.params += options;
        return this.hub.postJson(url, body, this.opts)
            .pipe(map((reponse: HttpResponse<T>) => reponse.body));
    }

    post<T>(url: string, body: any, options?: any): Observable<T> {
        this.opts.params += options;
        url = this.setKeyToUrl(url);
        this.subject$.next(null);


        return this.hub.post(url, body, this.opts)
            .pipe(
                catchError(err => {
                    this.destroy$.next();
                    this.destroy$.complete();
                    return err
                }),
                map((reponse: HttpResponse<T>) => reponse.body));
    }

    putJson<T>(url: string, body: any, options?: any): Observable<T> {
        this.opts.params += options;
        url = this.setKeyToUrl(url);


        return this.hub.putJson(url, body, this.opts)
            .pipe(map((reponse: HttpResponse<T>) => reponse.body));
    }

    put<T>(url: string, body: any, options?: any): Observable<T> {
        this.opts.params += options;
        url = this.setKeyToUrl(url);

        this.subject$.next(null);

        return this.hub.put(url, body, this.opts)
            .pipe(
                catchError(err => {
                    this.destroy$.next();
                    this.destroy$.complete();
                    return err
                }),
                map((reponse: HttpResponse<T>) => reponse.body));
    }

    deleteJson(url: string, options?: any): Observable<any> {
        this.opts.params += options;
        url = this.setKeyToUrl(url);


        return this.hub.deleteJson(url, this.opts)
    }

    delete(url: string, options?:any): Observable<HttpResponse<any>> {
        this.opts.params += options;
        url = this.setKeyToUrl(url);
        this.subject$.next(null);


        return this.hub.delete(url, this.opts)
            .pipe(
                catchError(err => {
                    this.destroy$.next();
                    this.destroy$.complete();
                    return err
                }),
                map((reponse: HttpResponse<unknown>) => reponse));
    }

    private setKeyToUrl(url: string) {
        try {

            const newUrl = new URL(url);
            newUrl.searchParams.append('gw-app-key', this.settingService.getAppKey());
            return newUrl.href;
        }
        catch {
            return url
        }
    }

    public keepAlive() {
        const filter: Filter = {
            _limit: 0,
            _offset: 0,
            requestTypeId: AbsenceType.HEALTH,
            employeeId: '',
            impId: '',
            _sort: 'string'
        }
        const params = stringify(filter);
        const url = this.settingService.GetParameters + '?' + String(params);
        this.get(url)
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe();

    }
}
