import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DssUploadComponent } from '@dss/components/upload';
import { UploadServiceResponse } from '@taa/file-transfer';
import { DocumentInfo } from '../../interfaces/document-info.interface';
import { SpinnerComponent } from '../spinner/spinner.component';
import { FileService } from './../../services/file.service';

const num3000 = 3000;
const num1024 = 1024;
const num2 = 2;
@Component({
  selector: 'wkd-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
  @Output() filesChange: EventEmitter<DocumentInfo> = new EventEmitter<DocumentInfo>();
  @Input() public required = false;
  private documentsError: string[] = [];
  public showError = false;
  public docErro: string;
  constructor(private readonly fileService: FileService,
              public dialog: MatDialog,
              ) { }

  async valueChange(fileList: FileList) {

    this.resetError();
    if (this.isValidFileList(fileList)) {

      for (const property in fileList) {
        if (this.validExtension(fileList[property]) ) {

          this.openDialog();

          try {
            const response = await this.upload(fileList[property]);
            this.emit({
              file: fileList[property],
              cftFileCode: response.gnId?response.gnId:response.fileCode.gnId,
              description: ''
            });
            this.closeDialog();
          }
          catch (erro) {
              this.doErrorHandlingOnSendFile(fileList, property);
          }
        }
        else if(fileList[property] instanceof File){
            this.documentsError.push(`Arquivo "${fileList[property].name}" com extensão inválida ou tamanho excedido!`);
        }

      }

      this.closeDialog();
      this.verifyError();
      return true;
    }
    return false;


  }
  private doErrorHandlingOnSendFile(fileList: FileList, property: string) {
    this.documentsError.push(`Erro ao enviar o arquivo "${fileList[property].name}"!`);
    this.closeDialog();
  }

  private isValidFileList(fileList: FileList) {
    return (fileList instanceof FileList) && fileList.length > 0;
  }

  private resetError(){
    this.showError = false;
    this.documentsError = [];
  }
  private verifyError(){
    let timeout = 0;
    this.documentsError.forEach((erro)=>{
      setTimeout(()=>{
        this.showError = true;
        this.docErro = erro;
      }, timeout);
      timeout += num3000;
    })
  }
  private validExtension(e: File) {
    if(!(e instanceof File )){
      return false;
    }

    const extension = e.name.substr(e.name.lastIndexOf('.') + 1).toLocaleLowerCase();
    const size = Number((e.size / (num1024 * num1024)).toFixed(num2));
    const limit = 4;

    return (extension === 'png' ||
    extension === 'pdf' ||
    extension === 'jpeg' ||
    extension === 'jpg') &&
      size <= limit

  }

  private emit(value: DocumentInfo) {
    this.filesChange.emit(value);
  }

  async upload(file: File): Promise<any> {
    return this.fileService.upload(file).toPromise();
  }

    private openDialog(){
        this.dialog.open(SpinnerComponent,
            {
              disableClose: true,
              panelClass: 'custom-modalbox'
            })
    }

    private closeDialog(){
        this.dialog.closeAll();
    }

}
