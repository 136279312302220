import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wkd-file-resume',
  templateUrl: './file-resume.component.html',
  styleUrls: ['./file-resume.component.scss']
})
export class FileResumeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
