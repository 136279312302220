import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppRoutes } from './../../enum/routes.enum';

@Component({
  selector: 'wkd-generic-error-page',
  templateUrl: './generic-error-page.component.html',
  styleUrls: ['./generic-error-page.component.scss']
})
export class GenericErrorPageComponent {
  @Input() public mainTitle: string;
  @Input() public subTitle: string;
  @Input() public buttonTitle: string;
  @Input() public buttonLink: string;
  @Input() public icon: string;
  public external: boolean;
  public appRoutes = AppRoutes;
  constructor(public router: Router, 
              public route: ActivatedRoute,
              public cd: ChangeDetectorRef) {
    this.validateState();
  }

  public validateState() {
    const routerData = this.router.getCurrentNavigation();
    if (!routerData || !routerData.extras || !routerData.extras.state || !routerData.extras.state.redirect) {
      this.router.navigateByUrl('/');
      return;
    }

    this.external = routerData && routerData.extras && routerData.extras.state && routerData.extras.state.external;
  }
  ngAfterViewInit(){
    this.route.queryParams.subscribe(params => {
      this.buttonLink = !!params['redirect']?params['redirect']:this.buttonLink ;         
      this.cd.detectChanges(); 
    });
  }

}
