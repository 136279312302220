import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthToString'
})
export class MonthNumberToStringPipe implements PipeTransform {
  months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];

  transform( monthNumberic: number): string {
    const MONTHS_END_INDEX = 11;
    if (! monthNumberic || monthNumberic < 0 || monthNumberic > MONTHS_END_INDEX) {
      return '';
    }
    return this.getMonthString(monthNumberic);
  }

  getMonthString(position: number): string {
    if(position < 0 || position > this.months.length - 1){
      return ''
    }
    return this.months[position];
  }
}
