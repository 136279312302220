import { CertificateData } from './../../interfaces/certificate-data.interface';
import { Component, Input, OnInit } from '@angular/core';
import { AbsenceType } from '../../enum/absence-type.enum';
import { CrmData } from '../../interfaces/crm-data.interface';


@Component({
  selector: 'wkd-questions-resume',
  templateUrl: './questions-resume.component.html',
  styleUrls: ['./questions-resume.component.scss']
})
export class QuestionsResumeComponent implements OnInit {
  @Input() public label = '';
  @Input() public isExtension: boolean;
  @Input() public certificateData: CertificateData;
  @Input() public crmData: CrmData;
  @Input() public requestType: AbsenceType;

  public absenceType = AbsenceType;
  constructor() { }

  ngOnInit(): void {
  }

}
