import { Component } from '@angular/core';

@Component({
    selector: 'wkd-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent {

    
}