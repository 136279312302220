import { Component, OnInit } from '@angular/core';
import { AppRoutes } from '../../enum/routes.enum';
import { AbsenceService } from '../../services/absence.service';
import { take } from 'rxjs/operators';
import { AbsenceResponse } from '../../interfaces/absence-response.interface';
import { BaseResponse } from './../../interfaces/base-response.interface';



@Component({
  selector: 'wkd-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(private readonly absenceService: AbsenceService){

  }
  public appRoutes = AppRoutes;
  public data: BaseResponse<AbsenceResponse[]>;
  public getAbsence() {

    this.absenceService
      .getAbsence({
        requestTypeId: null,
        _limit: null,
        _offset: null,
        _sort: null
      }).pipe(
        take(1),
      ).subscribe(
        (response) => {
          this.data = response;
        },
        (e)=>{
          this.data = { _content:[]};


        }
      )
  }
}

