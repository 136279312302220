const ALERT_1 = 'A data fim do seu contrato é superior a data fim do atestado cadastrado. Orientamos que siga o pedido do atestado sendo a data fim do atestado a mesma do seu contrato, preenchida automaticamente em sistema.';
const ALERT_2 = 'A data fim do seu contrato é superior a data fim da licença cadastrada. Orientamos que siga o pedido da licença sendo a data fim a mesma do seu contrato, preenchida automaticamente em sistema.';

export class AlertMessageTypeForm {

  public static alertMassages =
    {
      3: ALERT_1,
      4: ALERT_1,
      5: ALERT_1,
      6: ALERT_1,
      7: ALERT_1,
      8: ALERT_1,
      9: ALERT_1,
      10: ALERT_1,
      11: ALERT_1,
      12: ALERT_1,
      13: ALERT_1,
      14: ALERT_1,
      15: ALERT_1,
      20: ALERT_2,
      21: ALERT_2,
      22: ALERT_1,
      23: ALERT_2,
    }

  public static getMessageFromType(type : number) : string {
    return this.alertMassages[type];
  }
}
