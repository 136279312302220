import { Injectable } from '@angular/core';
import { stringify } from 'querystring-es3';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HubConnectorService, SettingsService } from '.';
import { BaseResponse } from '../interfaces/base-response.interface';
import { CrmQuery } from '../interfaces/crm-query.interface';
import { CrmResponse } from '../interfaces/crm-response.interface';
import { AuthService } from './auth.service';
import { WkdEncryptionService } from './wkd-encryption.service';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(public readonly hubService: HubConnectorService,
    public readonly authService: AuthService,
    private readonly encrypt: WkdEncryptionService,
    public settingService: SettingsService) { }

    getCrm(filter: CrmQuery): Observable<CrmResponse> {
      filter.employeeId = this.authService.getAuthId();
      const params = stringify(filter);
      const url = this.settingService.GetCrm.replace('{crm_code}', filter.uf.toUpperCase() + filter.crm_code) + '?' + String(params);
      return this.hubService.get<BaseResponse<CrmResponse[]>>(url)
        .pipe(
          switchMap(async (response) => {
            await this.encrypt.decryptObjectByReference(response._content[0], ['crmUf']);
            return response
          }),
          catchError(err => {
            const response: BaseResponse<CrmResponse[]> = {
              _pageable: {
                _limit: 30,
                _offset: 0,
                _pageNumber: 0,
                _pageElements: 0,
                _totalPages: 5,
                _totalElements: 0
              },
              _content: [
                {
                  crmCode: '',
                  doctorName: '',
                  crmUf: ''
                }
              ]
            }
            return of(response);
          }),
          map(
            (response) => {
              return response._content[0]
            }
          )
        )

  }
}
