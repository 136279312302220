import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DssUploadComponent } from '@dss/components';
import { DocumentInfo } from '../../interfaces/document-info.interface';

@Component({
  selector: 'wkd-file-teste',
  templateUrl: './file-teste.component.html',
  styleUrls: ['./file-teste.component.scss']
})
export class FileTesteComponent implements OnInit {
  files: DocumentInfo[];
  fileName;
  @ViewChild('upload') public uploadElement: DssUploadComponent;
  @Input() public required = false;
  @Output() public change: EventEmitter<FileList> = new EventEmitter<FileList>();


  private _accept = '*';
  public get accept() : string {
    return this._accept;
  }
  @Input() public set accept(v : string) {
    this._accept = v;
    this.uploadElement.inputFile.nativeElement.accept = this._accept;
  }

  private _multiple = true;
  public get multiple() : boolean {
    return this._multiple;
  }
  @Input() public set multiple(v : boolean) {
    this._multiple = v;
    this.uploadElement.inputFile.nativeElement.multiple = this._multiple;
  }

  constructor() { }

  ngOnInit() {
  }
  valueChange(e: FileList) {
    this.uploadElement.files = null;
    this.uploadElement.title = '';
    const emitFile = this.allowedFiles(e);
    if(emitFile.length > 0){
      this.fileName = e[0].name;
      this.change.emit(emitFile);
      this.files = null;
    }
    return false;
  }

  private allowedFiles(e: FileList) {
    if( this.accept === '*') {
      return e;
    }
    else {
      const response =  new DataTransfer();
      Object.keys(e).forEach((key,index) => {
        if((e[key] instanceof File )) {
          const extension = e[key].name.substr(e[key].name.lastIndexOf('.') + 1).toLocaleLowerCase();
          if(this.accept.toLowerCase().includes(extension)) {
            response.items.add(e[key]);
          }
        }
      });

      return response.files;
    }
  }
}
