import { Injectable } from '@angular/core';
import { stringify } from 'querystring-es3';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HubConnectorService, SettingsService } from '.';
import { BaseResponse } from '../interfaces/base-response.interface';
import { Filter } from '../interfaces/filter.interface';
import { TrafficAccident } from './../interfaces/traffic-accident.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TrafficAccidentService {
  constructor(public readonly hubService: HubConnectorService,
    public readonly authService: AuthService,
    public settingService: SettingsService) { }

    getTrafficAccidents(filter: Filter): Observable<BaseResponse<TrafficAccident[]>> {
      filter.employeeId = this.authService.getAuthId();
      const params = stringify(filter);
      const url = this.settingService.GetTrafficAccidents + '?' + String(params);
      return this.hubService.get<BaseResponse<TrafficAccident[]>>(url)
        .pipe(
          catchError(err => {
            const response: BaseResponse<TrafficAccident[]> = {
              _pageable: {
                _limit: 30,
                _offset: 0,
                _pageNumber: 0,
                _pageElements: 0,
                _totalPages: 5,
                _totalElements: 0
              },
              _content: []
            }
            return of(response);
          }),
        );
    }
}
