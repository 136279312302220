import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, take } from 'rxjs/operators';

import { AbsenceType } from '../../enum/absence-type.enum';
import { TrafficAccident } from '../../interfaces/traffic-accident.interface';
import { TrafficAccidentService } from '../../services/traffic-accident.service';
import { BaseDropDown } from './../../interfaces/base-dropwdown.interface';


@Component({
  selector: 'wkd-traffic-accident-select',
  templateUrl: './traffic-accident-select.component.html',
  styleUrls: ['./traffic-accident-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrafficAccidentSelectComponent),
      multi: true
    }
  ]
})
export class TrafficAccidentSelectComponent implements OnInit, ControlValueAccessor {
  @Input() public label = 'Acidente de trânsito';
  @Input() public isError: boolean;
  @Input() public isSuccess: boolean;
  @Input() public absenceType: AbsenceType;

  public disabled: boolean;
  private _value: TrafficAccident;
  public get value(): TrafficAccident {
    return this._value;
  }
  public set value(v: TrafficAccident) {
    this._value = v;
  }

  onChange = (value: TrafficAccident) => { };
  onTouched = () => { };
  public accidents: BaseDropDown[] = [];

  constructor(private readonly trafficAccidentService: TrafficAccidentService) { }


  ngOnInit(): void {
    this.getTrafficAccidents();
  }

  private getTrafficAccidents() {
    this.trafficAccidentService
        .getTrafficAccidents({
          requestTypeId: this.absenceType,
          _sort: null,
          _limit: 1000,
          _offset: 0
        })
        .pipe(
            take(1),
            map((accidents)=>{
               const content = accidents._content;
               const response: BaseDropDown[] = content.map((accident) =>{
                 return {
                   value: accident,
                   viewValue: accident.descriptionText
                 }
               })
              return response;
            })
        ).subscribe(
          (response)=>{
            this.accidents = response;
          }
        );
  }

  writeValue(obj: TrafficAccident): void {
    this.value = obj;
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
