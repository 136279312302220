import { AbsenceType } from './../../enum/absence-type.enum';
import { StateResponse } from './../../interfaces/state-response.interface';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'wkd-doctor-data',
  templateUrl: './doctor-data.component.html',
  styleUrls: ['./doctor-data.component.scss']
})
export class DoctorDataComponent implements OnInit {
  public validState = true;
  public states: string;
  @Input() formGroupName: string;
  @Input() absenceType: AbsenceType;
  public form: FormGroup;
  constructor(private readonly cd: ChangeDetectorRef,
              private readonly rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  setStateValid(event: boolean) {
    this.validState = event || this.form.controls.state.valid;
  }
}
