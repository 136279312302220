import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wkd-empty-data-message',
  templateUrl: './empty-data-message.component.html',
  styleUrls: ['./empty-data-message.component.scss']
})
export class EmptyDataMessageComponent{
  @Input() message = 'Não existem atestados para serem consultados';

}
