import { Injectable } from '@angular/core';

// Models/Services locais
declare let CryptoJS: any;

@Injectable()
export class StorageService {
    salt = '8fbbf95f1e5678899cb285b6051846a7';

    setItem(key: string, value: any): void {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), this.salt);
        localStorage.setItem(key, encryptedData);
    }
    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    getItem(key: string): any {
        if (localStorage.getItem(key)) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key).toString(), this.salt);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


        }
        return undefined;
    }

    clear(): void {
        localStorage.clear();
    }
}
