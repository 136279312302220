import { BaseResponse } from './../../interfaces/base-response.interface';
import { FAQ } from './../../interfaces/faq.interface';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { FaqService } from './../services/faq.service';
import { AbsenceType } from '../../enum/absence-type.enum';

@Component({
  selector: 'wkd-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None,  
})
export class FaqComponent implements OnInit {
  @Input() faqItems: BaseResponse<FAQ[]>;
  @Input() public absenceType: AbsenceType = AbsenceType.HEALTH;
  constructor(private readonly faqService: FaqService) { }

  ngOnInit(): void {
  }

  private getFaq(){
    this.faqService.getFaq(this.absenceType).subscribe(
      (response) => {
        this.faqItems = response;
      },
      (e) => {
        this.faqItems = undefined;
      }
    );
  }
}
