import { CrmService } from './../../services/crm.service';
import { CrmMockService } from './../../test/mock/crm-mock.service';
import { CrmResponse } from './../../interfaces/crm-response.interface';
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, of, Subject } from 'rxjs';
import { debounceTime, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbsenceType } from './../../enum/absence-type.enum';
import { MatDialog } from '@angular/material';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'wkd-crm-input',
  templateUrl: './crm-input.component.html',
  styleUrls: ['./crm-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CrmInputComponent),
      multi: true
    }
  ]
})
export class CrmInputComponent implements ControlValueAccessor {
  public disabled: boolean;
  @Input() public isError = false;
  @Input() public isSuccess = false;
  @Input() public state: string;
  @Input() public absenceType: AbsenceType;
  @ViewChild('inputCRM') private readonly inputCRM: ElementRef;
  @Output() public validState: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() private readonly crmResponse: EventEmitter<CrmResponse> = new EventEmitter<CrmResponse>(null);

  public crmFound: boolean;
  private _value: string;
  private  readonly destroy$: Subject<any> = new Subject<any>();
  public get value(): string {
    return this._value;
  }
  public set value(v: string) {
    this._value = v;
  }

  onChange = (value: string) => { };
  onTouched = () => { };

  constructor(public readonly crmService: CrmService,
              public dialog: MatDialog,) { }

  ngOnChanges(e) {
    if (e.state && !e.state.firstChange && e.state.currentValue) {
      this.emitValidState();
      this.getCRM();
    }
  }


  public getCRM() {
    this.searchCRM()
      .pipe(
        take(1),
      )
      .subscribe(
        (data) => {
          this.closeDialog();
          this.validateAndEmit(data);
        },
        (err)=>{
          this.closeDialog();
        }
      )
  }

  public searchCRM() {
    if (!this.stateValid() || !this.value || this.value.toString().trim() === '') {
      this.emitValidState();
      return of({
        crmCode: this.value ? this.value.toString() : '',
        doctorName: '',
        crmUf: ''
      });
    }
    else {
      this.openDialog();

      return this.crmService
        .getCrm({
          requestTypeId: this.absenceType,
          crm_code: this.value.toString(),
          _limit: 1000,
          _offset: 0,
          _sort: null,
          uf: this.state
        })
        .pipe(
          take(1)
        )
    }
  }


  private validateAndEmit(data: CrmResponse) {
    if (data && data.crmCode && data.doctorName && data.doctorName.trim() !== '') {
      this.isSuccess = true;
      this.crmResponse.emit(data);
    }
    else {
      this.crmResponse.emit(
        {
          crmCode: this.value ? this.value.toString() : '',
          doctorName: '',
          crmUf: this.state || ''
        }
      );

      this.isSuccess = false;
    }
    this.onChange(this.value);
  }

  writeValue(obj: string): void {
    this.value = obj;
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private emitValidState() {
    this.validState.emit(this.stateValid());
  }

  private stateValid() {
    return !!this.state;
  }
  private openDialog() {
    this.dialog.open(SpinnerComponent,
      {
        disableClose: true,
        panelClass: 'custom-modalbox'
      })
  }

  private closeDialog() {
    this.dialog.closeAll();
  }
}
