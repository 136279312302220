import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/pt-br'
const MIN_SEC = 60;
const DECIMAL = 10;
const WEEK_DAYS = 7;
const datePattern = 'DD/MM/YYYY';
@Injectable()
export class ManipulationDatetimeService {
    currentDate: Date = new Date();


    getDigit(event?: KeyboardEvent) {
        return (event) ? event.key : null;
    }

    hourToMinute(value: string): number {
        let negative = false;

        if (!value) {
          return 0;
        }
        const values = value.trim().split(':');
        if (values[0].trim().startsWith('-')) {
            negative = true;
        }

        const v1 = parseInt(values[0]);
        const v2 = v1 < 0 || negative ? parseInt(values[1]) * (-1) : parseInt(values[1]);
        return v1 * MIN_SEC + v2;
    }

    minuteToHour(value: number): string {
        let negative = false;
        if (value < 0) {
            negative = true;
            value = value * -1;
        }
        const h = Math.floor(value / MIN_SEC);
        const m = Math.floor(value % MIN_SEC);

        return `${(negative ? '-' : '')}${(h < DECIMAL ? '0' + h.toString() : h)}:${(m < DECIMAL ? '0' + m.toString() : m)}`;
    }

    getCurrentDate(): Date {
        return this.currentDate;
    }

    getInitDate(date?: Date): Date {
        if (!date) {
          date = this.currentDate;
        }
        let initDate: Date;
        const currentDayWeek = date.getDay();
        for (let i = 0, j = 1; i < WEEK_DAYS; i++, j--) {
            if (currentDayWeek === i) {
                initDate = new Date(date.getTime());
                initDate.setDate(initDate.getDate() + j);
            }
        }
        return initDate;
    }

    isValidDate(date: string): boolean {
        const minTest = /^(\d{1}\d{1}):([0-5]{1}\d{1})$/;
        return minTest.test(date);
    }

    isNotEmptyDate(date: string): boolean {
        return this.isValidDate(date) && (this.hourToMinute(date) > 0);
    }

    verifyDateToBlur(date: string): boolean {
        if (!date) {
          return false;
        }
        const WEEK_DAYS_ = 5;
        const dateString = date.length > WEEK_DAYS_ ? date.slice(0, WEEK_DAYS_) : date;
        return this.isNotEmptyDate(dateString) && this.isValidDate(dateString);
    }

    isDateInMaxLimit(date: string, maxMinutes: number) {
        return this.hourToMinute(date) <= maxMinutes;
    }

    calculateDiffDays(initDate: string, endDate: string) {
        const init = moment(initDate, datePattern).locale('pt-br');
        const end = moment(endDate, datePattern).locale('pt-br');
        return isNaN(end.diff(init, 'days')+1) ? null:end.diff(init, 'days')
    }

    convertPtBRtoGlobal(date: string){

        return moment(date, datePattern).locale('pt-br').format('MM/DD/YYYY');
    }
    isDateInMinLimit(date: string, minMinutes = 0) {
        return this.hourToMinute(date) >= minMinutes;
    }

    // verifyDateField(date: string, event?: KeyboardEvent): string {
    //     let dateString = date;
    //     let dateReturn = '00:00';
    //     const crt = event && event.key ? event.key : null;


    // private isNumber(value): boolean {
    //     return !isNaN(value) && typeof value === 'number';
    // }

    // private isNumberGroup(values = []): boolean {
    //     return values.filter(value => (!isNaN(value) && typeof value === 'number')).length === values.length;
    // }

    // private isNumberKey(evt: KeyboardEvent): boolean {
    //     const charCode = (evt.which) ? evt.which : evt.keyCode;
    //     return this.charCodeIsNumber(charCode);
    // }

    // private charCodeIsNumber (charCode): boolean {
    //     const CODE31 = 31;
    //     const CODE48 = 48;
    //     const CODE57 = 57;
    //     return !(charCode > CODE31 && (charCode < CODE48 || charCode > CODE57));
    // }


    minutesInLimit(minutes: number, limitMinutes?: number): boolean {
        if (!limitMinutes) {
          return true;
        }
        return minutes <= limitMinutes;
    }

    compareDate(dateCompare: Date, dateDefault?: Date): boolean {
        const dCompare = new Date(dateCompare).setHours(0, 0, 0, 0);
        const date = !dateDefault ? new Date(this.currentDate).setHours(0, 0, 0, 0) : new Date(dateDefault).setHours(0, 0, 0, 0);
        return new Date(dCompare).getTime() === new Date(date).getTime();
    }

    compareListDates(dateCompare: Date, listDate: string[]): boolean {
        let result = false;
        listDate.map(date => {
            const d = moment(dateCompare).format('YYYY-MM-DD');
            const d2 = moment(date).format('YYYY-MM-DD');
            if (d === d2) {
                result = true;
            }
        });
        return result;
    }
}
