import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, take } from 'rxjs/operators';

import { BaseDropDown } from '../../interfaces/base-dropwdown.interface';
import { ReasonResponse } from '../../interfaces/reason-response.interface';
import { AbsenceReasonService } from './../../services/absence-reason.service';

@Component({
  selector: 'wkd-reason-select',
  templateUrl: './reason-select.component.html',
  styleUrls: ['./reason-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReasonSelectComponent),
      multi: true
    }
  ]
})
export class ReasonSelectComponent implements ControlValueAccessor {
  @Input() public label = 'Motivo *';
  @Input() public errorMessage: string;
  @Input() public absenceType: number;
  @Input() public isError = false;
  @Input() public isSuccess = false;
  public reasons: BaseDropDown[] = [];

  private _value: ReasonResponse;
  public disabled: boolean;
  public get value(): ReasonResponse {
    return this._value;
  }
  public set value(v: ReasonResponse) {
    this._value = v;
    this.onChange(this.value);

  }
  onChange = (value: ReasonResponse) => { };
  onTouched = () => { };

  constructor(public readonly reasonService: AbsenceReasonService) { }



  ngOnChanges(e) {
    if (e.absenceType) {
      this.getReasons();
    }
  }

  public getReasons() {
    this.reasonService.getReasons(
      {
        requestTypeId: this.absenceType,
        _sort: null,
        _limit: 1000,
        _offset: 0
      }
    )
      .pipe(
        take(1),
        map((response) => {

          const reasons: BaseDropDown[] = response._content.map((reason) => {

            return {
              value: reason,
              viewValue: `${reason.descriptionText} `
            }
          });
          return reasons;
        })
      )
      .subscribe(
        (response) => {
          this.reasons = response;
        }
      )
  }


  writeValue(obj: ReasonResponse): void {
    this.value = obj;
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
