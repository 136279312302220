import { AppRoutes } from './../../enum/routes.enum';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wkd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent{
  public appRoutes = AppRoutes;
}
