
import { EncriptionService } from '@afe/base-geral';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WkdEncryptionService {

  constructor(private readonly encryptService: EncriptionService) {

  }

  public async encryptObjectByReference(obj: any, ignoredProperties: string[] = []) {
    const encrypted = obj;
    for (const key of Object.keys(obj)) {
      const value = encrypted[key]
      if (value && typeof value === 'object' && !ignoredProperties.includes(key) && value !== null){
        encrypted[key] = await this.encryptObjectByReference(encrypted[key], ignoredProperties);

      }
      else if (!ignoredProperties.includes(key) && value !== null) {
        encrypted[key] = await this.encryptService.encrypt(encrypted[key].toString()).toPromise();
      }
      else {
        encrypted[key] = value;
      }
    }
    return encrypted
  }


  public async decryptObjectByReference(obj: any, ignoredProperties: string[] = []) {
    const decrypted = obj;
    for (const key in decrypted) {
      const value = decrypted[key];
      if (value && typeof value === 'object' && !ignoredProperties.includes(key) && value !== null){
        decrypted[key] = await this.decryptObjectByReference(value, ignoredProperties);
      }
      else if (!ignoredProperties.includes(key) && value !== null) {
        decrypted[key] = await this.encryptService.decrypt(value).toPromise();
      }
      else {
        decrypted[key] = value;
      }
    }
    return decrypted
  }

  public getKeys(){
    return this.encryptObjectByReference({xpto: 'xpto'})
  }
}
