  import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekDateVisible'
})
export class WeekDateVisiblePipe implements PipeTransform {
  public initDate: Date;
  public endDate: Date;
  public currentDate: Date;

  constructor() {
    this.currentDate = new Date();
    this.currentDate.setHours(0, 0, 0, 0);
  }

  transform(array, field: string) {
    if (!field || field.trim() === ''  || array.length < 1) {
      return array;
    }

    return Array.from(array).filter(item => {
      return this.setLimitDates(item[field]);
    });
  }

  setLimitDates(date: Date): boolean {
    const currentDayWeek = this.currentDate.getDay();
    const WEEK_DAYS = 7;
    const DAYS = 4;

    for (let i = 0, j = 1; i < WEEK_DAYS; i++ , j--) {
      if (currentDayWeek === i) {
        this.initDate = new Date(this.currentDate.getTime());
        this.initDate.setDate(this.initDate.getDate() + j);

        this.endDate = new Date(this.initDate.getTime());
        this.endDate.setDate(this.endDate.getDate() + DAYS);
      }
    }
    return this.isDateValid(date);
  }

  private isDateValid(date: Date): boolean {
    const dCompare = new Date(date).setHours(0, 0, 0, 0);
    return (this.initDate.getTime() <= dCompare && dCompare <= this.endDate.getTime());
  }
}
