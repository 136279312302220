import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { BaseDropDown } from '../../interfaces/base-dropwdown.interface';
import { StateService } from './../../services/state.service';

@Component({
  selector: 'wkd-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StateSelectComponent),
      multi: true
    }
  ]
})
export class StateSelectComponent implements OnInit, ControlValueAccessor {
  @Input() public label: string;
  @Input() public errorMessage: string;
  @Input() public isError = false;
  @Input() public isSuccess = false;
  public states: BaseDropDown[] = [];

  private _value: string;
  public disabled: boolean;
  public get value(): string {
    return this._value;
  }
  public set value(v: string) {
    this._value = v;
    this.onChange(this.value);

  }
  onChange = (value: string) => { };
  onTouched = () => { };

  constructor(private readonly stateService: StateService) { }

  ngOnInit(): void {
    this.getStates();
  }
  writeValue(obj: string): void {
    this.value = obj;
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  private getStates() {
    this.stateService.getStates(
      {
        _sort: null,
        _limit: 1000,
        _offset: 0
      }
    )
      .pipe(
        take(1),
        map((response) => {
          return  response._content.map((state) => {
            return {
              value: state.uf,
              viewValue: `${state.nmState} (${state.uf}) `
            }
          });

        })
      )
      .subscribe(
        (response)=>{
          this.states = this.sortStates(response);
        }
      )
  }

  private sortStates(states: BaseDropDown[]): BaseDropDown[] {

    const firstState = states.filter(
      (item) => item.value === "SP" || item.value === "RJ"
    );

    const arrayOfStates = states
      .filter(function (item) {
        return item.value !== "SP";
      })
      .filter(function (item) {
        return item.value !== "RJ";
      })
      .sort((a, b) => {
        return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
      });

    arrayOfStates.unshift(firstState[0]);
    arrayOfStates.unshift(firstState[1]);

    return arrayOfStates;
  }
}
