
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'decrypt' })
export class DecryptPipe implements PipeTransform {
    value: string;

    constructor() { }

    transform(value: string) {
        this.value = value;
        return this.value;
    }
}
