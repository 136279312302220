import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { CrmResponse } from './../../interfaces/crm-response.interface';
import { AbsenceType } from './../../enum/absence-type.enum';

@Component({
  selector: 'wkd-crm-and-name',
  templateUrl: './crm-and-name.component.html',
  styleUrls: ['./crm-and-name.component.scss']
})
export class CrmAndNameComponent implements OnInit, AfterViewChecked {

  @Input() public state: string;
  @Output() public validState: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() formGroupName: string;
  @Input() absenceType: AbsenceType;
  public form: FormGroup;
  constructor(private readonly cd: ChangeDetectorRef,
    private readonly rootFormGroup: FormGroupDirective) {

  }
  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }
  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  public emitValidState(event: boolean) {
    this.validState.emit(event && this.validCRM());
  }

  public setValue(crm: CrmResponse) {
    this.form.controls.doctorName.setValue(crm.doctorName);
    this.form.controls.number.setValue(crm.crmCode);
  }
  private validCRM() {
    return this.form.valid && this.form.controls.number.value && this.form.controls.number.value.toString().trim() !== '' && parseInt(this.form.controls.number.value) > 0
  }
}
