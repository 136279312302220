import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, take } from 'rxjs/operators';

import { AbsenceType } from '../../enum/absence-type.enum';
import { BaseDropDown } from '../../interfaces/base-dropwdown.interface';
import { TypeResponse } from '../../interfaces/type-response.interface';
import { AbsenceTypeService } from './../../services/absence-type.service';

@Component({
  selector: 'wkd-medical-certificate-type-select',
  templateUrl: './medical-certificate-type-select.component.html',
  styleUrls: ['./medical-certificate-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedicalCertificateTypeSelectComponent),
      multi: true
    }
  ]
})
export class MedicalCertificateTypeSelectComponent implements OnInit, ControlValueAccessor {
  @Input() public label = 'Selecione o tipo';
  @Input() public absenceType: AbsenceType;
  @Input() public isError = false;
  @Input() public isSuccess: boolean;

  public disabled: boolean;
  private _value: TypeResponse;
  public get value(): TypeResponse {
    return this._value;
  }
  public set value(v: TypeResponse) {
    this._value = v;
  }

  public absences = [];
  onChange = (value: TypeResponse) => { };
  onTouched = () => { };
  public types: BaseDropDown[] = [];

  constructor(private readonly typeService: AbsenceTypeService) { }


  ngOnInit(): void {
    this.getTypes();
  }

  private getTypes() {
    this.typeService.getTypes({
                                requestTypeId: this.absenceType,
                                _sort: null,
                                _limit: 1000,
                                _offset: 0
                              })
                                .pipe(
                                  take(1),
                                  map((response) => {
                                    return response._content.map((reason) => {
                                      return {
                                        value: {
                                          ...reason,
                                        },
                                        viewValue: reason.description
                                      }
                                    });

                                  })
                                )
                                .subscribe(
                                  (response) => {
                                    this.types = this.sortTypes(response);
                                  }
                                )
  }

  writeValue(obj: TypeResponse): void {
    this.value = obj;
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private sortTypes(types: BaseDropDown[]): BaseDropDown[] {
    return types.sort((a, b) => {
        return a.value.nrSort < b.value.nrSort ? -1 : a.value.nrSort > b.value.nrSort ? 1 : 0;
      });
  }
}
