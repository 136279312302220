import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { FaqService } from '../../components/services/faq.service';
import { FAQ } from '../../interfaces/faq.interface';

import { AbsenceType } from '../../enum/absence-type.enum';
import { AppRoutes } from '../../enum/routes.enum';
import { AbsenceResponse } from '../../interfaces/absence-response.interface';
import { BaseResponse } from '../../interfaces/base-response.interface';
import { AbsenceService } from '../../services/absence.service';
import { StorageService } from './../../services/storage.service';

const num30 = 30;
@Component({
  selector: 'wkd-list-base',
  templateUrl: './list-base.component.html',
  styleUrls: ['./list-base.component.scss']
})
export class ListBaseComponent{
  @Input() mainTitle: string;
  @Input() requestTypeId: AbsenceType;
  @Input() icon = 'management-health';
  @Input() buttonText = 'Incluir novo atestado médico';
  @Input() route: AppRoutes;
  @Input() welcomeMessage: string;
  @Output() itemsPageChange: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() selectedPageChange: EventEmitter<number> = new EventEmitter<number>(null);
  public data: BaseResponse<AbsenceResponse[]>;
  private currentPage = 0;
  private itemsPerPage = num30;
  public noitifyCreated: boolean;
  public absenceType = AbsenceType;
  public faqItems: BaseResponse<FAQ[]>;
  loading: boolean;
  public successMessage = "Seu atestado foi enviado com sucesso";

  constructor(
    public storageService: StorageService,
    public readonly absenceService: AbsenceService,
    private readonly faqService: FaqService
  ) {}

  ngOnInit() {
    this.verifySuccess();
    this.getAbsence();
    this.bindFaq();
  }

  public verifySuccess(){
    const created = this.storageService.getItem('created') as {created: boolean, type: AbsenceType};
    if(created && created.created){
      this.noitifyCreated = true;
      this.successMessage = this.absenceType.MATERNITY === created.type?'Sua solicitação de licença foi enviada com sucesso':'Seu atestado foi enviado com sucesso'
      this.storageService.removeItem('created');
      return true
    }
    else{
      return false;
    }
  }

  filterByPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getAbsence();
  }

  filterByItems(itemsPerPage: number) {
    this.currentPage = 0;
    this.itemsPerPage = itemsPerPage;
    this.getAbsence();
  }

  public getAbsence() {
    this.loading = true;
    this.absenceService
      .getAbsence({
        requestTypeId: this.requestTypeId,
        _limit: this.itemsPerPage,
        _offset: this.currentPage,
        _sort: null
      }).pipe(
        take(1),
      ).subscribe(
        (response) => {
          this.loading = false;
          this.data = response;
        },
        (e) => {
          this.loading = false;
          this.data = { _content:[]};
        }
      )
  }

  private bindFaq(): void {
    this.faqService.getFaq(this.absenceType.HEALTH).subscribe(
      (response) => {
        this.faqItems = response;
      },
      (e) => {
        this.faqItems = undefined;
      }
    );
  }
}
