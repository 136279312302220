import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wkd-medical-certificate-extension',
  templateUrl: './medical-certificate-extension.component.html',
  styleUrls: ['./medical-certificate-extension.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(()=> MedicalCertificateExtensionComponent),
      multi: true
    }
  ]
})
export class MedicalCertificateExtensionComponent implements ControlValueAccessor {
  public disabled: boolean;

  private _value : boolean;
  public get value() : boolean {
    return this._value;
  }
  public set value(v : boolean) {
      this._value = v;
      this.onChange(this._value);
  }
  onChange= (value: boolean)=>{};
  onTouched= ()=>{};

  valueChanges(value: string){
    if(value){
      const stringValue = "true";
      const boolValue = stringValue.toLowerCase() === value.toString();
      this.onChange(boolValue);
      return true
    }
    else{
      return false
    }
  }
  writeValue(obj: boolean): void {
    this.value = obj;
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
