import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DocumentInfo } from '../../interfaces/document-info.interface';

@Component({
  selector: 'wkd-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentsComponent),
      multi: true
    }
  ]
})
export class DocumentsComponent implements ControlValueAccessor {
  @Input() public required = false;
  public disabled: boolean;
  private _value: DocumentInfo[] = [];

  public get value(): DocumentInfo[] {
    return this._value;
  }

  public set value(v: DocumentInfo[]) {
    this._value = v;
    this.onChange(this._value);
  }
  onChange = (value: DocumentInfo[]) => { };
  onTouched = () => { };
  constructor() { }

  writeValue(obj: DocumentInfo[]): void {
    if (obj) {
      this.value = obj;
    }
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateFileList(e: DocumentInfo){
    const values = [...this.value];
    values.push(e);
    this.value = [...values];
  }

}
