import { Injectable } from '@angular/core';
import { stringify } from 'querystring-es3';
import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AbsenceResponse } from '../interfaces/absence-response.interface';
import { BaseResponse } from '../interfaces/base-response.interface';
import { CreateAbsence } from '../interfaces/create-absence.interface';
import { CreatedAbsenceResponse } from '../interfaces/created-absence-response.interface';
import { Filter } from '../interfaces/filter.interface';
import { AuthService } from './auth.service';
import { HubConnectorService } from './hubConnector.service';
import { SettingsService } from './settings.service';
import { WkdEncryptionService } from './wkd-encryption.service';

@Injectable({
  providedIn: 'root'
})
export class AbsenceService {

  private readonly ignoredProperties: string[] = [
    'requestTypeId',
    'startDate',
    'endDate',
    'reasonId',
    'comments',
    'fileName',
    'description',
    'isExtension',
    'issueDate',
    'crmUF',
    'cids',
    'trafficAccidentId',
    'isExtension',
    'isExtension',
    'isExtension',
    'isExtension',

  ]
  constructor(public readonly hubService: HubConnectorService,
    public readonly authService: AuthService,
    public readonly wkdEncrypt: WkdEncryptionService,
    public settingService: SettingsService) { }

  getAbsence(filter: Filter): Observable<BaseResponse<AbsenceResponse[]>> {
    filter.impId = this.authService.getAuthId();
    const params = stringify(filter);
    const url = this.settingService.GetRequests + '?' + String(params);
    return  from(this.wkdEncrypt.getKeys())
              .pipe(
                switchMap(()=>{
                  return this.hubService.get<BaseResponse<AbsenceResponse[]>>(url)
                }),
                switchMap(async (response)=>{
                  await this.wkdEncrypt.decryptObjectByReference(response._content, ['requestType', 'id',  'description']);
                  return response
                }),
              )

  }

  createAbsence(data: CreateAbsence): Observable<CreatedAbsenceResponse> {
    const url = this.settingService.PostRequests;
    const copyData = this.clone(data);

    return from(this.wkdEncrypt.encryptObjectByReference(copyData, this.ignoredProperties))
              .pipe(
                switchMap((response) => {
                  return this.hubService.post<CreatedAbsenceResponse>(url, response);
                }),
                switchMap(async (response)=>{
                  await this.wkdEncrypt.decryptObjectByReference(response, ['id', 'message']);
                  return response
                }),
              )
  }

  confirmCreation(requestId: number, data: CreateAbsence): Observable<CreatedAbsenceResponse> {
    const url = this.settingService.PutRequests.replace('{absence_request_id}', requestId.toString());
    const copyData = this.clone(data);
    return from(this.wkdEncrypt.encryptObjectByReference(copyData, this.ignoredProperties))
              .pipe(
                switchMap((response) => {
                  return this.hubService.put<CreatedAbsenceResponse>(url, response);
                }),
                switchMap(async (response)=>{
                  await this.wkdEncrypt.decryptObjectByReference(response, ['id']);
                  return response
                }),
              )
  }

  public clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

}
