import { Component } from '@angular/core';
import { DssBreadcrumbInterface } from '@dss/components/breadcrumb';

import { AppRoutes } from './../../enum/routes.enum';

@Component({
  selector: 'wkd-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  public items: Array<DssBreadcrumbInterface> = [
    { label: 'Portal externo', url: '' },

  ];

}
