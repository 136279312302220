import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CidMockService } from './../../test/mock/cid-mock.service';
import { AbsenceType } from './../../enum/absence-type.enum';
import { CidResponse } from './../../interfaces/cid-response.interface';
import { take } from 'rxjs/operators';
import { CidService } from '../../services/cid.service';


@Component({
  selector: 'wkd-cid-input',
  templateUrl: './cid-input.component.html',
  styleUrls: ['./cid-input.component.scss']
})
export class CidInputComponent implements OnInit {
  @Input() public label = 'Digite o CID';
  @Input() public isError: boolean;
  @Input() public isSuccess: boolean;
  @Input() public absenceType: AbsenceType;
  @Input() public search = true;
  @Output() public cid: EventEmitter<CidResponse> = new EventEmitter<CidResponse>();
  public loadingCid = false;
  public cidForm: FormGroup = new FormGroup({
    cid: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][0-9]{0,3}")])
  });
  public cidNotFound: boolean;

  constructor(public readonly cidService: CidService) { }

  ngOnInit(): void {
  }

  public validateAndQuery(event: Event){
    if(!this.cidForm.valid || !this.search || this.loadingCid){
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    this.loadingCid = true;
    this.cidService.getCid({
                            absence_type_id: this.absenceType,
                            cid_code: this.cidForm.controls.cid.value,
                            _limit: 1000,
                            _offset: 0,
                            _sort: null,
                          }).pipe(
                            take(1)
                          ).subscribe(
                            (response)=>{
                              if(response && response.cid && response.description){
                                this.emitCid(response);
                                this.resetData();
                              }
                              else{
                                this.cidNotFound = true;
                              }
                              this.loadingCid = false;

                            },
                            (erro)=>{
                              this.cidNotFound = true;
                              this.loadingCid = false;
                            }
                          )

  }
  public cidInvalid(){
    return this.cidForm.controls.cid.hasError('pattern');
  }

  public resetData(){
    this.cidForm.reset();
    this.cidNotFound = false;

  }

  public emitCid(cid: CidResponse){
    this.cid.emit(cid);
  }

}
