import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wkd-container-box',
  templateUrl: './container-box.component.html',
  styleUrls: ['./container-box.component.scss']
})
export class ContainerBoxComponent implements OnInit {
  @Input() label: string;
  @Input() padding = "2rem";
  constructor() { }

  ngOnInit(): void {
  }

}
